import * as React from "react";

import Stack from "@mui/material/Stack";

import useAuth from "app/hooks/useAuth";
import { ADD_POLICY, ADD_USER, DELETE_USER, VIEW_USER } from "Permissions";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { Field, Formik } from "formik";
import "moment/locale/en-in";
import { Button, Col, Grid, Heading, Panel, PanelGroup, Row } from "rsuite";

import { Uploader, Message, Loader, useToaster } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";

import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { AttachFile, RemoveRedEye } from "@mui/icons-material";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import useAPINew from "app/hooks/useAPINew";

// TODO: Get tehsil, dist and state from Village name
function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const today = new Date();
today.setHours(0, 0, 0, 0);

const validationSchema = yup.object({
  PolicyNo: yup
    .string()
    .matches(/^\d{9}$/, "Valid 9 digits policy No Required")
    .nullable(true),
});

export default function AddPolicy() {
  const { permissions, user } = useAuth();
  const navigate = useNavigate();
  const [refresh, setRefresh] = React.useState(false);
  const axiosPrivate = useAxiosPrivate();

  const toaster = useToaster();
  //const uploader = React.useRef();
  const [fileList1, setFileList1] = React.useState([]);
  const [familyList, setFamilyList] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const ReferenceList = useAPINew("GetReferenceList.php");
  const AgencyCodeList = useAPINew("GetAgencyCodeList.php");
  const [loading, setLoading] = React.useState(false);
  const [UID, setUID] = React.useState(null);
  const theme = useTheme();

  const initialState = {
    DataAdded: false,
    DocumentAdded: false,
  };
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "New":
        return { ...initialState };
      case "NEW_ENTRY_DONE":
        return { ...initialState, DataAdded: true };

      case "DOC_ADDED":
        return { ...initialState, DocumentAdded: true };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
    }
    return () => {};
  }, [refresh]);
  const initialValues = {
    CompletionDate: new Date(),
    DOC: new Date(),
    GST: 4.5,
    DOBR: new Date(),
  };

  function GetFamilyDetails(FamilyHeadID) {
    axiosPrivate
      .post("GetRelatedFamilyList.php", { UID: FamilyHeadID })
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          setFamilyList(result.Data);
        }
        if (result.status === 0) {
          setFamilyList([{ value: "", title: "" }]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  const GetFamilyByID = async (ID) => {
    try {
      const responce = await axiosPrivate.post("GetFamilyByID.php", {
        UID: ID,
      });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        const newData = {
          DOBR:
            result.Data[0].DOBR === "0000-00-00" || result.Data[0].DOBR === null
              ? null
              : new Date(result.Data[0].DOBR),
          DOBG:
            result.Data[0].DOBG === "0000-00-00" || result.Data[0].DOBG === null
              ? null
              : new Date(result.Data[0].DOBG),
          DOM:
            result.Data[0].DOM === "0000-00-00" || result.Data[0].DOM === null
              ? null
              : new Date(result.Data[0].DOM),
          UID: ID,
        };
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };
  function handleAttachment() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`Are you done with attaching documents?`}</p>
            <button
              onClick={() => {
                dispatch({ type: "DOC_ADDED" });
                onClose();
              }}
            >
              Yes
            </button>
            <button onClick={onClose}>No</button>
          </div>
        );
      },
    });
  }

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <Heading
          level={5}
          style={{
            textAlign: "center",
            flex: 1,
          }}
        >
          Add Policy Details
        </Heading>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={16}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  try {
                    axiosPrivate
                      .post("AddPolicy.php", {
                        ...values,
                        AgencyCode: values.AgencyCode.value,
                        FamilyMemberID: values.FamilyMemberID.value,
                        ReferenceID: values.ReferenceID.value,
                        DOC: values?.DOC
                          ? moment(values.DOC).format("YYYY-MM-DD")
                          : null,
                        CompletionDate: values?.CompletionDate
                          ? moment(values.CompletionDate).format("YYYY-MM-DD")
                          : null,
                      })
                      .then((response) => {
                        const result = response.data;
                        if (result.status === 1) {
                          console.log(result);
                          toaster.push(
                            <Message type="success" bordered showIcon>
                              <strong>Success!</strong> {result?.Success}
                            </Message>
                          );
                          dispatch({ type: "NEW_ENTRY_ADDED" });
                          setUID(result.ID);
                        }
                        if (result.status === 0) {
                          toaster.push(
                            <Message type="error" bordered showIcon>
                              <strong>Error!</strong> {result.Error}
                            </Message>
                          );
                        }
                        setSubmitting(false);
                        // resetForm();
                      })
                      .catch((error) => {
                        console.error("Error submitting data:", error);
                        setSubmitting(false);
                      });
                  } catch (error) {
                    console.error("Error submitting data:", error);
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValidating,
                }) => (
                  <form className="target">
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading
                        level={6}
                        style={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Reference ID
                      </Heading>
                    </Row>
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <Field
                          name="ReferenceID"
                          disabled={UID}
                          component={Autocomplete}
                          key="ID"
                          options={
                            ReferenceList?.error
                              ? [{ value: "", title: "" }]
                              : ReferenceList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 300 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "ReferenceID",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                            GetFamilyDetails(value.value);
                            setFieldValue("FamilyMemberID", {
                              value: "",
                              title: "",
                            });
                          }}
                          value={
                            Boolean(values.ReferenceID)
                              ? values.ReferenceID
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="ReferenceID"
                              label="ReferenceID"
                              variant="standard"
                              error={
                                touched.ReferenceID &&
                                Boolean(errors.ReferenceID)
                              }
                              helperText={
                                touched.ReferenceID && errors.ReferenceID
                              }
                            />
                          )}
                        />
                        {values?.ReferenceID?.value && (
                          <Button
                            appearance="link"
                            onClick={() => {
                              console.log(values);
                              navigate(
                                "/Prospects/ViewProspect/" +
                                  values?.ReferenceID?.value
                              );
                            }}
                          >
                            <RemoveRedEye />
                          </Button>
                        )}
                        &nbsp; &nbsp;
                        <Field
                          name="FamilyMemberID"
                          disabled={UID}
                          component={Autocomplete}
                          key="ID"
                          options={
                            familyList?.length > 0
                              ? familyList
                              : [{ value: "", title: "" }]
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={async (_, value) => {
                            setFieldValue(
                              "FamilyMemberID",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                            try {
                              const responce = await axiosPrivate.post(
                                "GetFamilyByID.php",
                                {
                                  UID: value.value,
                                }
                              );
                              //console.log(responce);
                              const result = responce.data;
                              if (result.status === 1) {
                                setFieldValue(
                                  "DOBR",
                                  result.Data[0].DOBR === "0000-00-00" ||
                                    result.Data[0].DOBR === null
                                    ? null
                                    : new Date(result.Data[0].DOBR)
                                );
                                const currentDate = moment();
                                const birthDate = moment(
                                  result.Data[0].DOBR === "0000-00-00" ||
                                    result.Data[0].DOBR === null
                                    ? null
                                    : new Date(result.Data[0].DOBR)
                                );
                                const age = currentDate.diff(
                                  birthDate,
                                  "years"
                                );
                                if (age >= 18) {
                                  setFieldValue("MinorPolicy", "NO");
                                } else {
                                  setFieldValue("MinorPolicy", "YES");
                                }
                              }
                              if (result.status === 0) {
                                toaster.push(
                                  <Message type="error" bordered showIcon>
                                    <strong>Error!</strong> {result?.Error}
                                  </Message>
                                );
                              }
                            } catch (error) {
                              console.log(error);
                              toaster.push(
                                <Message type="error" bordered showIcon>
                                  <strong>Error!</strong> Error occured
                                </Message>
                              );
                            }
                          }}
                          value={
                            Boolean(values.FamilyMemberID)
                              ? values.FamilyMemberID
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="FamilyMemberID"
                              label="Family Member"
                              variant="standard"
                              error={
                                touched.FamilyMemberID &&
                                Boolean(errors.FamilyMemberID)
                              }
                              helperText={
                                touched.FamilyMemberID && errors.FamilyMemberID
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Policy details</Heading>
                    </Row>
                    {/* <br /> */}
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginBottom: 10 }}
                    >
                      <TextField
                        disabled={UID}
                        label="Policy Holder Name"
                        size="small"
                        variant="standard"
                        name="PolicyHolderName"
                        value={values.PolicyHolderName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.PolicyHolderName &&
                          Boolean(errors.PolicyHolderName)
                        }
                        helperText={
                          touched.PolicyHolderName && errors.PolicyHolderName
                        }
                        style={{ width: 300 }}
                      />{" "}
                    </Row>

                    <Row gutter={1} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <Field
                          disabled={UID}
                          name="AgencyCode"
                          component={Autocomplete}
                          key="ID"
                          options={
                            AgencyCodeList?.error
                              ? [{ value: "", title: "" }]
                              : AgencyCodeList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 150 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "AgencyCode",
                              value || { value: "", title: "" }
                            );
                          }}
                          value={
                            Boolean(values.AgencyCode)
                              ? values.AgencyCode
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="AgencyCode"
                              label="AgencyCode"
                              variant="standard"
                              error={
                                touched.AgencyCode && Boolean(errors.AgencyCode)
                              }
                              helperText={
                                touched.AgencyCode && errors.AgencyCode
                              }
                            />
                          )}
                        />
                        &nbsp; &nbsp;
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={UID}
                            label="Completion Date"
                            variant="standard"
                            size="small"
                            name="CompletionDate"
                            format="dd-MM-yyyy"
                            value={values.CompletionDate}
                            onChange={(value) =>
                              setFieldValue("CompletionDate", value, true)
                            }
                            onBlur={handleBlur}
                            error={
                              touched.CompletionDate &&
                              Boolean(errors.CompletionDate)
                            }
                            helperText={
                              touched.CompletionDate && errors.CompletionDate
                            }
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <TextField
                          disabled={UID}
                          label="Policy No"
                          size="small"
                          variant="standard"
                          name="PolicyNo"
                          value={values.PolicyNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.PolicyNo && Boolean(errors.PolicyNo)}
                          helperText={touched.PolicyNo && errors.PolicyNo}
                          style={{ width: 200 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginTop: 10 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={UID}
                            label="DOC"
                            variant="standard"
                            size="small"
                            name="DOC"
                            format="dd-MM-yyyy"
                            value={values.DOC}
                            onChange={(value) =>
                              setFieldValue("DOC", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.DOC && Boolean(errors.DOC)}
                            helperText={touched.DOC && errors.DOC}
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <TextField
                          disabled={UID}
                          label="Plan Term"
                          size="small"
                          variant="standard"
                          name="PayingTerm"
                          value={values.PayingTerm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.PayingTerm && Boolean(errors.PayingTerm)
                          }
                          helperText={touched.PayingTerm && errors.PayingTerm}
                          style={{ width: 200 }}
                        />{" "}
                        &nbsp; &nbsp;
                        <TextField
                          disabled={UID}
                          label="Sum Assured"
                          size="small"
                          variant="standard"
                          name="SumAssured"
                          value={values.SumAssured}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.SumAssured && Boolean(errors.SumAssured)
                          }
                          helperText={touched.SumAssured && errors.SumAssured}
                          style={{ width: 200 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Premium Details</Heading>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginTop: 10 }}
                    >
                      <FormControl variant="standard" sx={{ minWidth: 100 }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          Mode
                        </InputLabel>
                        <Select
                          disabled={UID}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label="Mode"
                          name="Mode"
                          value={values?.Mode || ""}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "MLY-E-Nach") {
                              setFieldValue("ENachMode", "YES", true);
                            } else {
                              setFieldValue("ENachMode", "NO", true);
                            }
                          }}
                          error={touched.Mode && Boolean(errors.Mode)}
                          helperText={touched.Mode && errors.Mode}
                        >
                          {[
                            "YLY",
                            "HLY",
                            "QLY",
                            "MLY-3-ENACH",
                            "MLY-E-Nach",
                            "SINGLE",
                            "MLY-SSS",
                          ].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(
                                name,
                                [
                                  "YLY",
                                  "HLY",
                                  "QLY",
                                  "MLY-3-ENACH",
                                  "MLY-E-Nach",
                                  "SINGLE",
                                  "MLY-SSS",
                                ],
                                theme
                              )}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      &nbsp;&nbsp;
                      <TextField
                        disabled={UID}
                        label="FIRST BASIC PREMIUM (NO GST)"
                        size="small"
                        variant="standard"
                        style={{ width: 250 }}
                        name="FirstBasic"
                        value={values.FirstBasic}
                        onChange={(e) => {
                          handleChange(e);
                          const FirstBasic = e.target.value;
                          if (!isNaN(values.GST) && !isNaN(FirstBasic)) {
                            const val = (FirstBasic * values.GST) / 100;
                            setFieldValue("GSTAmount", Math.round(val), true);
                            setFieldValue(
                              "FirstTotal",
                              Math.round(+val + +FirstBasic),
                              true
                            );
                            if (values?.Mode === "YLY") {
                              setFieldValue(
                                "FirstYLYBasic",
                                Math.round(FirstBasic * 1),
                                true
                              );
                            } else if (values?.Mode === "HLY") {
                              setFieldValue(
                                "FirstYLYBasic",
                                Math.round(FirstBasic * 2),
                                true
                              );
                            } else if (
                              values?.Mode === "QLY" ||
                              values?.Mode === "MLY-3-ENACH"
                            ) {
                              setFieldValue(
                                "FirstYLYBasic",
                                Math.round(FirstBasic * 4),
                                true
                              );
                            } else if (
                              values?.Mode === "MLY-SSS" ||
                              values?.Mode === "MLY-E-Nach"
                            ) {
                              setFieldValue(
                                "FirstYLYBasic",
                                Math.round(FirstBasic * 12),
                                true
                              );
                            } else {
                              setFieldValue(
                                "FirstYLYBasic",
                                Math.round(FirstBasic),
                                true
                              );
                            }
                          }
                        }}
                        onBlur={handleBlur}
                        error={touched.FirstBasic && Boolean(errors.FirstBasic)}
                        helperText={touched.FirstBasic && errors.FirstBasic}
                      />{" "}
                      &nbsp;&nbsp;
                      <TextField
                        disabled={UID}
                        label="GST%"
                        size="small"
                        variant="standard"
                        style={{ width: 50 }}
                        name="GST"
                        value={values.GST}
                        onChange={(e) => {
                          handleChange(e);
                          if (!isNaN(values.GST)) {
                            const val = (values.FirstBasic * values.GST) / 100;
                            setFieldValue("GSTAmount", Math.round(val), true);
                          }
                        }}
                        onBlur={handleBlur}
                        error={touched.GST && Boolean(errors.GST)}
                        helperText={touched.GST && errors.GST}
                      />{" "}
                      &nbsp;&nbsp;
                      <TextField
                        disabled={true}
                        label="GST Amount"
                        size="small"
                        variant="standard"
                        style={{ width: 100 }}
                        name="GSTAmount"
                        value={values.GSTAmount}
                        InputLabelProps={{
                          shrink: Boolean(values.GSTAmount),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.GSTAmount && Boolean(errors.GSTAmount)}
                        helperText={touched.GSTAmount && errors.GSTAmount}
                      />{" "}
                    </Row>
                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <TextField
                        disabled={true}
                        label="FIRST TOTAL PREMIUM (GST)"
                        size="small"
                        variant="standard"
                        style={{ width: 150 }}
                        name="FirstTotal"
                        value={values.FirstTotal}
                        InputLabelProps={{
                          shrink: Boolean(values.FirstTotal),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.FirstTotal && Boolean(errors.FirstTotal)}
                        helperText={touched.FirstTotal && errors.FirstTotal}
                      />{" "}
                      &nbsp;&nbsp;
                      <TextField
                        disabled={true}
                        label="FIRST YLY PREMIUM (BASIC)"
                        size="small"
                        variant="standard"
                        style={{ width: 180 }}
                        name="FirstYLYBasic"
                        value={values.FirstYLYBasic}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: Boolean(values.FirstYLYBasic),
                        }}
                        error={
                          touched.FirstYLYBasic && Boolean(errors.FirstYLYBasic)
                        }
                        helperText={
                          touched.FirstYLYBasic && errors.FirstYLYBasic
                        }
                      />{" "}
                      {/* &nbsp;&nbsp;
                      <TextField
                        disabled={UID}
                        label="GST % FIRST YEAR PREMIUM"
                        size="small"
                        variant="standard"
                        style={{ width: 180 }}
                        name="FirstYLYTotal"
                        value={values.FirstYLYTotal}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.FirstYLYTotal && Boolean(errors.FirstYLYTotal)
                        }
                        helperText={
                          touched.FirstYLYTotal && errors.FirstYLYTotal
                        }
                      />{" "}
                      &nbsp; &nbsp; */}
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Other details</Heading>
                    </Row>
                    <Row gutter={1} style={{ marginTop: 20, marginBottom: 10 }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateField
                          disabled={true}
                          label="DOBR"
                          variant="standard"
                          size="small"
                          name="DOBR"
                          format="dd-MM-yyyy"
                          value={values.DOBR}
                          onChange={(value) =>
                            setFieldValue("DOBR", value, true)
                          }
                          onBlur={handleBlur}
                          error={touched.DOBR && Boolean(errors.DOBR)}
                          helperText={touched.DOBR && errors.DOBR}
                        />
                      </LocalizationProvider>
                      &nbsp; &nbsp;
                      <FormControl variant="standard" sx={{ minWidth: 200 }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          MINOR POLICY HOLDER
                        </InputLabel>
                        <Select
                          disabled={true}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label="MinorPolicy"
                          name="MinorPolicy"
                          value={values?.MinorPolicy || ""}
                          onChange={handleChange}
                          error={
                            touched.MinorPolicy && Boolean(errors.MinorPolicy)
                          }
                          helperText={touched.MinorPolicy && errors.MinorPolicy}
                        >
                          {["YES", "NO"].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, ["YES", "NO"], theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* &nbsp;&nbsp;
                      <TextField
                        disabled={UID}
                        label="Official Email ID"
                        size="small"
                        variant="standard"
                        style={{ width: 200 }}
                        name="OfficialEmailID"
                        value={values.OfficialEmailID}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.OfficialEmailID &&
                          Boolean(errors.OfficialEmailID)
                        }
                        helperText={
                          touched.OfficialEmailID && errors.OfficialEmailID
                        }
                      />{" "} */}
                    </Row>
                    <Row gutter={1} style={{ marginTop: 20, marginBottom: 10 }}>
                      <FormControl variant="standard" sx={{ minWidth: 100 }}>
                        <InputLabel id="demo-simple-select-standard-ENachMode">
                          E-NACH
                        </InputLabel>
                        <Select
                          disabled={UID}
                          labelId="demo-simple-select-standard-ENachMode"
                          id="demo-simple-select-standard"
                          label="E-NACH"
                          name="ENachMode"
                          value={values?.ENachMode || ""}
                          onChange={handleChange}
                          error={touched.ENachMode && Boolean(errors.ENachMode)}
                          helperText={touched.ENachMode && errors.ENachMode}
                        >
                          {["YES", "NO"].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, ["YES", "NO"], theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      &nbsp; &nbsp;
                      <FormControl variant="standard" sx={{ minWidth: 180 }}>
                        <InputLabel id="demo-simple-select-standard-PolicBondRecieved">
                          BOND RECIEVED
                        </InputLabel>
                        <Select
                          disabled={UID}
                          labelId="demo-simple-select-standard-PolicBondRecieved"
                          id="demo-simple-select-standard"
                          label="BOND RECIEVED"
                          name="PolicBondRecieved"
                          value={values?.PolicBondRecieved || ""}
                          onChange={handleChange}
                          error={
                            touched.PolicBondRecieved &&
                            Boolean(errors.PolicBondRecieved)
                          }
                          helperText={
                            touched.PolicBondRecieved &&
                            errors.PolicBondRecieved
                          }
                        >
                          {["YES", "NO"].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, ["YES", "NO"], theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      &nbsp; &nbsp;
                      <FormControl variant="standard" sx={{ minWidth: 180 }}>
                        <InputLabel id="demo-simple-select-standard-FormSubmittedOnline">
                          FORM ONLINE SUBMIT
                        </InputLabel>
                        <Select
                          disabled={UID}
                          labelId="demo-simple-select-standard-FormSubmittedOnline"
                          id="demo-simple-select-standard"
                          label="FORM ONLINE SUBMIT"
                          name="FormSubmittedOnline"
                          value={values?.FormSubmittedOnline || ""}
                          onChange={handleChange}
                          error={
                            touched.FormSubmittedOnline &&
                            Boolean(errors.FormSubmittedOnline)
                          }
                          helperText={
                            touched.FormSubmittedOnline &&
                            errors.FormSubmittedOnline
                          }
                        >
                          {["YES", "NO"].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, ["YES", "NO"], theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Row>
                    <Row gutter={1} style={{ marginTop: 20, marginBottom: 10 }}>
                      <TextField
                        disabled={UID}
                        label="VIMPRO ID"
                        size="small"
                        variant="standard"
                        style={{ width: 200 }}
                        name="VIMPROID"
                        value={values.VIMPROID}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.VIMPROID && Boolean(errors.VIMPROID)}
                        helperText={touched.VIMPROID && errors.VIMPROID}
                      />{" "}
                    </Row>
                    <br />
                    {user.Type === "SuperAdmin" && (
                      <>
                        <Row gutter={6} style={{ marginBottom: 10 }}>
                          <Heading level={6}>LEAD FINAL STATUS</Heading>
                        </Row>
                        <Row gutter={6} className="show-grid">
                          <Stack direction="row" spacing={1}>
                            <TextField
                              disabled={UID}
                              label="COMMISSION %"
                              size="small"
                              variant="standard"
                              style={{ width: 120 }}
                              name="CommPer"
                              value={values.CommPer}
                              onChange={(e) => {
                                handleChange(e);
                                const CommPer = e.target.value;
                                if (!isNaN(values.GST) && !isNaN(CommPer)) {
                                  const val =
                                    (CommPer * values.FirstBasic) / 100;
                                  setFieldValue(
                                    "CommOfFirstBasisPremium",
                                    Math.round(val),
                                    true
                                  );

                                  if (values?.Mode === "YLY") {
                                    setFieldValue(
                                      "CommOfFirstBasisYLYPremium",
                                      Math.round(val * 1),
                                      true
                                    );
                                  } else if (values?.Mode === "HLY") {
                                    setFieldValue(
                                      "CommOfFirstBasisYLYPremium",
                                      Math.round(val * 2),
                                      true
                                    );
                                  } else if (
                                    values?.Mode === "QLY" ||
                                    values?.Mode === "MLY-3-ENACH"
                                  ) {
                                    setFieldValue(
                                      "CommOfFirstBasisYLYPremium",
                                      Math.round(val * 4),
                                      true
                                    );
                                  } else if (
                                    values?.Mode === "MLY-SSS" ||
                                    values?.Mode === "MLY-E-Nach"
                                  ) {
                                    setFieldValue(
                                      "CommOfFirstBasisYLYPremium",
                                      Math.round(val * 12),
                                      true
                                    );
                                  } else {
                                    setFieldValue(
                                      "CommOfFirstBasisYLYPremium",
                                      Math.round(val),
                                      true
                                    );
                                  }
                                }
                              }}
                              onBlur={handleBlur}
                              error={touched.CommPer && Boolean(errors.CommPer)}
                              helperText={touched.CommPer && errors.CommPer}
                            />{" "}
                            &nbsp;&nbsp;
                            <TextField
                              disabled={true}
                              label="FIRST COMMISSION"
                              size="small"
                              variant="standard"
                              style={{ width: 150 }}
                              name="CommOfFirstBasisPremium"
                              value={values.CommOfFirstBasisPremium}
                              InputLabelProps={{
                                shrink: Boolean(values.CommOfFirstBasisPremium),
                              }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.CommOfFirstBasisPremium &&
                                Boolean(errors.CommOfFirstBasisPremium)
                              }
                              helperText={
                                touched.CommOfFirstBasisPremium &&
                                errors.CommOfFirstBasisPremium
                              }
                            />{" "}
                            &nbsp;&nbsp;
                            <TextField
                              disabled={true}
                              label="FIRST YLY COMMISSION"
                              size="small"
                              variant="standard"
                              style={{ width: 150 }}
                              name="CommOfFirstBasisYLYPremium"
                              value={values.CommOfFirstBasisYLYPremium}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: Boolean(
                                  values.CommOfFirstBasisYLYPremium
                                ),
                              }}
                              error={
                                touched.CommOfFirstBasisYLYPremium &&
                                Boolean(errors.CommOfFirstBasisYLYPremium)
                              }
                              helperText={
                                touched.CommOfFirstBasisYLYPremium &&
                                errors.CommOfFirstBasisYLYPremium
                              }
                            />{" "}
                          </Stack>
                        </Row>
                        &nbsp; &nbsp;
                        <Row gutter={6} style={{ marginBottom: 10 }}>
                          <Heading level={6}>Expenses</Heading>
                        </Row>
                        {/* <br /> */}
                        <Row gutter={1} style={{ marginBottom: 10 }}>
                          <TextField
                            disabled={UID}
                            label="DR-2"
                            size="small"
                            variant="standard"
                            style={{ width: 150 }}
                            name="DR2"
                            value={values.DR2}
                            onChange={(e) => {
                              handleChange(e);
                              const val = e.target.value;
                              if (!isNaN(val)) {
                                setFieldValue(
                                  "NetSavingFirst",
                                  parseInt(
                                    parseFloat(values.CommOfFirstBasisPremium) -
                                      parseFloat(
                                        parseFloat(val) +
                                          parseFloat(values.OtherExpenses) +
                                          parseFloat(values.OtherComm)
                                      )
                                  ),
                                  true
                                );
                                setFieldValue(
                                  "NetSavingYLY",
                                  parseInt(
                                    parseFloat(
                                      values.CommOfFirstBasisYLYPremium
                                    ) -
                                      parseFloat(
                                        parseFloat(val) +
                                          parseFloat(values.OtherExpenses) +
                                          parseFloat(values.OtherComm)
                                      )
                                  ),
                                  true
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            error={touched.DR2 && Boolean(errors.DR2)}
                            helperText={touched.DR2 && errors.DR2}
                          />{" "}
                          &nbsp; &nbsp;
                          <TextField
                            disabled={UID}
                            label="SERVICING EXPENCES"
                            size="small"
                            variant="standard"
                            style={{ width: 150 }}
                            name="OtherExpenses"
                            value={values.OtherExpenses}
                            onChange={(e) => {
                              handleChange(e);
                              const val = e.target.value;
                              if (!isNaN(val)) {
                                setFieldValue(
                                  "NetSavingFirst",
                                  Math.round(
                                    parseFloat(values.CommOfFirstBasisPremium) -
                                      parseFloat(
                                        parseFloat(values.DR2) +
                                          parseFloat(val) +
                                          parseFloat(values.OtherComm)
                                      )
                                  ),
                                  true
                                );
                                setFieldValue(
                                  "NetSavingYLY",
                                  parseInt(
                                    parseFloat(
                                      values.CommOfFirstBasisYLYPremium
                                    ) -
                                      parseFloat(
                                        parseFloat(values.DR2) +
                                          parseFloat(val) +
                                          parseFloat(values.OtherComm)
                                      )
                                  ),
                                  true
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.OtherExpenses &&
                              Boolean(errors.OtherExpenses)
                            }
                            helperText={
                              touched.OtherExpenses && errors.OtherExpenses
                            }
                          />{" "}
                          &nbsp; &nbsp;
                          <TextField
                            disabled={UID}
                            label="Dahiya Mitra Payout"
                            size="small"
                            variant="standard"
                            style={{ width: 150 }}
                            name="OtherComm"
                            value={values.OtherComm}
                            onChange={(e) => {
                              handleChange(e);
                              const val = e.target.value;
                              if (!isNaN(val)) {
                                setFieldValue(
                                  "NetSavingFirst",
                                  Math.round(
                                    parseFloat(values.CommOfFirstBasisPremium) -
                                      parseFloat(
                                        parseFloat(values.DR2) +
                                          parseFloat(values.OtherExpenses) +
                                          parseFloat(val)
                                      )
                                  ),
                                  true
                                );
                                setFieldValue(
                                  "NetSavingYLY",
                                  Math.round(
                                    parseFloat(
                                      values.CommOfFirstBasisYLYPremium
                                    ) -
                                      parseFloat(
                                        parseFloat(values.DR2) +
                                          parseFloat(values.OtherExpenses) +
                                          parseFloat(val)
                                      )
                                  ),
                                  true
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.OtherComm && Boolean(errors.OtherComm)
                            }
                            helperText={touched.OtherComm && errors.OtherComm}
                          />{" "}
                        </Row>
                        <Row gutter={1} className="show-grid">
                          <TextField
                            disabled={true}
                            label="APPROX FIRST INCOME"
                            size="small"
                            variant="standard"
                            style={{ width: 200 }}
                            name="NetSavingFirst"
                            value={values.NetSavingFirst}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(values.NetSavingFirst),
                            }}
                            error={
                              touched.NetSavingFirst &&
                              Boolean(errors.NetSavingFirst)
                            }
                            helperText={
                              touched.NetSavingFirst && errors.NetSavingFirst
                            }
                          />{" "}
                          &nbsp; &nbsp;
                          <TextField
                            disabled={true}
                            label="APPROX FIRST YLY INCOME"
                            size="small"
                            variant="standard"
                            style={{ width: 200 }}
                            InputLabelProps={{
                              shrink: Boolean(values.NetSavingYLY),
                            }}
                            name="NetSavingYLY"
                            value={values.NetSavingYLY}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.NetSavingYLY &&
                              Boolean(errors.NetSavingYLY)
                            }
                            helperText={
                              touched.NetSavingYLY && errors.NetSavingYLY
                            }
                          />{" "}
                        </Row>
                        <br />
                        <Row gutter={6} style={{ marginBottom: 10 }}>
                          <Heading level={6}>Feedback</Heading>
                        </Row>
                        <Row gutter={1} style={{ marginBottom: 10 }}>
                          <FormControl
                            variant="standard"
                            sx={{ minWidth: 180 }}
                          >
                            <InputLabel id="demo-simple-select-standard-Responce">
                              Responce
                            </InputLabel>
                            <Select
                              disabled={UID}
                              labelId="demo-simple-select-standard-Responce"
                              id="demo-simple-select-Responce"
                              label="Responce"
                              name="Responce"
                              value={values?.Responce || ""}
                              onChange={handleChange}
                              error={
                                touched.Responce && Boolean(errors.Responce)
                              }
                              helperText={touched.Responce && errors.Responce}
                            >
                              {["EXCELLENT", "BETTER", "GOOD", "NEGATIVE"].map(
                                (name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(
                                      name,
                                      [
                                        "EXCELLENT",
                                        "BETTER",
                                        "GOOD",
                                        "NEGATIVE",
                                      ],
                                      theme
                                    )}
                                  >
                                    {name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Row>
                      </>
                    )}
                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Stack direction="row" spacing={4}>
                        {UID ? (
                          <>
                            {!state.DocumentAdded && (
                              <LoadingButton
                                color="secondary"
                                onClick={handleAttachment}
                                loading={loading}
                                loadingPosition="Done"
                                startIcon={<AttachFile />}
                                variant="contained"
                              >
                                <span>Save</span>
                              </LoadingButton>
                            )}
                          </>
                        ) : (
                          (permissions.includes(ADD_POLICY) ||
                            user.Type === "SuperAdmin") && (
                            <LoadingButton
                              disabled={isSubmitting}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              onClick={handleSubmit}
                              loading={isValidating}
                              sx={{ my: 2 }}
                            >
                              {isSubmitting ? "Submitting..." : "Submit"}
                            </LoadingButton>
                          )
                        )}
                      </Stack>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Row className="show-grid">
                {UID && (
                  <Col xs={24} sm={24} md={24}>
                    <Uploader
                      fileListVisible={false}
                      disabled={state.DocumentAdded}
                      multiple={false}
                      listType="picture"
                      action="https://backend.dahiyainsurance.com/Upload/AddProfilePic.php"
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      data={{ UID, Type: "Policy" }}
                      onUpload={(File) => {
                        setUploading(true);
                        previewFile(File.blobFile, (value) => {
                          setFileInfo(value);
                        });
                      }}
                      onSuccess={(response, _) => {
                        setUploading(false);
                        toaster.push(
                          <Message type="success">
                            Uploaded successfully
                          </Message>
                        );
                        console.log(response);
                      }}
                      onError={(e) => {
                        console.log(e);
                        setFileInfo(null);
                        setUploading(false);
                        toaster.push(
                          <Message type="error">Upload failed</Message>
                        );
                      }}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                    >
                      <button style={{ width: 300, height: 300 }}>
                        {uploading && <Loader backdrop center />}
                        {fileInfo ? (
                          <img
                            alt=""
                            src={fileInfo}
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <AvatarIcon style={{ fontSize: 80 }} />
                        )}
                      </button>
                    </Uploader>

                    <Uploader
                      disabled={state.DocumentAdded}
                      removable={!state.DocumentAdded}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                      listType="picture-text"
                      fileList={fileList1}
                      multiple={true}
                      data={{ UID, Type: "Policy" }}
                      onChange={setFileList1}
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      action="https://backend.dahiyainsurance.com/Upload/UploadDocs.php"
                      renderFileInfo={(file, fileElement) => (
                        <span style={{ width: "100%", fontSize: 14 }}>
                          Name: {file.name}
                        </span>
                      )}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
}
