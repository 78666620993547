import * as React from "react";
import AgGrid from "app/components/AgGrid";
import {
  Tabs,
  Panel,
  Divider,
  Row,
  Grid,
  Col,
  useToaster,
  Message,
} from "rsuite";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce, filterParams } from "functions";
import SideDrawer from "app/components/SideDrawer";
import { LoadingButton } from "@mui/lab";
import { Field, Formik } from "formik";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import { useProps } from "./ProspectProvider";
import { LeadStatus, Status } from "app/DataSource";
import { LEADSTATUS } from "Permissions";
import useAuth from "app/hooks/useAuth";
import useAPINew from "app/hooks/useAPINew";

const validationSchema = yup.object({
  Status: yup.string().required("Status is required"),
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function ListProspects() {
  const { user, permissions } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [loadingByLead, setLoadingByLead] = React.useState(false);
  const [rowByStatusData, setRowByStatusData] = React.useState([{}]);
  const [rowByLeadStatusData, setRowByLeadStatusData] = React.useState([{}]);
  const [activeKey, setActiveKey] = React.useState("LEADSTATUS");
  const StatusList = useAPINew("GetReferenceStatusList.php");
  const LeadStatusList = useAPINew("GetProspectLeadStatusList.php");
  const axiosPrivate = useAxiosPrivate();
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const handleViewModalOpen = () => setOpenViewModal(true);
  const handleViewModalClose = () => setOpenViewModal(false);
  const theme = useTheme();
  const toaster = useToaster();
  const [selectedStatus, setSelectedStatus] = React.useState("ALL");
  const [selectedLeadStatus, setSelectedLeadStatus] = React.useState("ALL");
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    Status: null,
    Result: null,
    LeadStatus: null,
  });
  const ColorStatus = React.useMemo(() => {
    return ["#FC6E51", "#FFCE54", "#A0D468", "#4FC1E9"];
  }, []);
  const columns = React.useMemo(() => {
    return [
      {
        headerName: "Ref ID",
        field: "ID",
        minWidth: 180,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        field: "Date",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
        headerName: "First Visit Date",
      },
      { field: "Place", minWidth: 150, headerName: "First Visit Place" },
      {
        field: "Status",
        minWidth: 100,
        editable: true,
        cellStyle: (params) => {
          if (params.value === "HOT" || params.value === "Hot") {
            //mark police cells as red
            return { color: ColorStatus[0], fontWeight: "bold" };
          } else if (params.value === "WARM" || params.value === "Warm") {
            //mark police cells as red
            return { color: ColorStatus[1], fontWeight: "bold" };
          } else if (params.value === "COLD" || params.value === "Cold") {
            //mark police cells as red
            return { color: ColorStatus[2], fontWeight: "bold" };
          } else {
            //mark police cells as red
            return { color: ColorStatus[3] };
          }
        },
      },
      { field: "LeadStatus", minWidth: 140, editable: true },
      { field: "RefBy", minWidth: 150 },
      { field: "Gender", minWidth: 100 },
      { field: "MaritalStatus", minWidth: 100 },
      {
        field: "DOB",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        headerName: "Age",
        field: "Age",
        minWidth: 180,
        editable: false,
      },
      {
        headerName: "Name",
        minWidth: 300,
        field: "Full_Name",
      },

      { field: "Caste", minWidth: 150 },
      { field: "Category", minWidth: 150 },
      { field: "Purpose", minWidth: 150 },
      { field: "Address", minWidth: 150 },
      { field: "Village", minWidth: 150 },
      { field: "Tehsil", minWidth: 150 },
      { field: "District", minWidth: 150 },
      { field: "State", minWidth: 150 },
      { field: "PIN", minWidth: 150 },
      { field: "WhatsAppNo", minWidth: 150 },
      { field: "MobileNo", minWidth: 150, headerName: "Resi Mob No" },
      { field: "Email", minWidth: 150, headerName: "Email" },
      { field: "Profession", minWidth: 150 },
      { field: "WorkType", minWidth: 150, editable: false },
      {
        field: "Other",
        minWidth: 150,
        headerName: "CKYC Number",
        editable: false,
      },
      { field: "ConceptDiscussed", minWidth: 300, headerName: "First Meeting" },
      { field: "DiscussionDate", minWidth: 150 },
      { field: "Discussed", minWidth: 100 },
      { field: "FamilyMembers", minWidth: 100 },

      { field: "AddedBy", minWidth: 150 },
      { field: "AddedOn", minWidth: 150 },
      { field: "UniqueID", hide: true },
    ];
  }, []);
  const { updatePropsValue } = useProps();

  const handleUpdateMessage = () => {
    const NewData = filteredRows.map((dt) => dt.data.UniqueID);
    updatePropsValue(NewData);
    //console.log(propsValue);
  };
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      handleUpdateMessage();
      //console.log(filteredRows);
    }
    return () => {
      isSubscribed = false;
    };
  }, [filteredRows.length]);
  React.useEffect(() => {
    setLoading(false);
    setLoadingByLead(false);
    let isSubscribed = true;
    if (isSubscribed) {
      onGridReadyByStatus();
      //console.log(filteredRows);
    }
    return () => {
      isSubscribed = false;
    };
  }, [selectedStatus]);
  React.useEffect(() => {
    setLoading(false);
    setLoadingByLead(false);
    let isSubscribed = true;
    if (isSubscribed) {
      onByLeadStatusGridReady();
      //console.log(filteredRows);
    }
    return () => {
      isSubscribed = false;
    };
  }, [selectedLeadStatus]);
  React.useEffect(() => {
    // console.log(selectedRowData);
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.Name) {
        setInitialValues({
          UID: selectedRowData?.UniqueID,
          Status: selectedRowData?.Status,
        });
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.Name]);

  const UpdateStatus = (data) => {
    axiosPrivate
      .post("UpdateStatusReference.php", data)
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          console.log(result);
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result?.Success}
            </Message>
          );
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const handleChildData = (data) => {
    UpdateStatus(data);
  };

  const history = useNavigate();
  const location = useLocation();

  const onGridReadyByStatus = React.useCallback(
    (params) => {
      console.log("Status=> " + selectedStatus);
      setLoading(true);
      axiosPrivate
        .post("LoadProspectByStatus.php", { Status: selectedStatus })
        .then((responce) => {
          const result = responce.data;
          if (result.status === 1) setRowByStatusData(result.Data);
          else setRowByStatusData([]);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    },
    [selectedStatus]
  );

  const onByLeadStatusGridReady = React.useCallback(
    (params) => {
      console.log("Status=> " + selectedLeadStatus);
      setLoadingByLead(true);
      axiosPrivate
        .post("LoadProspectByLeadStatus.php", {
          LeadStatus: selectedLeadStatus,
        })
        .then((responce) => {
          const result = responce.data;
          if (result.status === 1) setRowByLeadStatusData(result.Data);
          else setRowByLeadStatusData([]);
          setLoadingByLead(false);
        })
        .catch((e) => {
          console.log(e);
          setLoadingByLead(false);
        });
    },
    [selectedLeadStatus]
  );

  const updateQueryParams = React.useCallback(
    (activeKey) => {
      const params = new URLSearchParams(location.search);
      params.set("activeKey", activeKey);
      history(`?${params.toString()}`, { replace: true });
    },
    [history, location.search]
  );

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const nameFromParams = params.get("activeKey") || "STATUS";
    setActiveKey(nameFromParams);
  }, [location.search]);

  return (
    <>
      <Panel>
        <Tabs
          activeKey={activeKey}
          onSelect={(d) => {
            setActiveKey(d);
            updateQueryParams(d);
            setLoading(false);
          }}
        >
          <Tabs.Tab eventKey="STATUS" title="STATUS">
            <div>
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="Status"
                  name="Status"
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                    //console.log("Status=> " + e.target.value);
                  }}
                  onSelect={(e) => {
                    setSelectedStatus(e.target.value);
                    //console.log("Status=> " + e.target.value);
                  }}
                >
                  {[...StatusList.data, "ALL"].map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(
                        name,
                        [...StatusList.data, "ALL"],
                        theme
                      )}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <AgGrid
                columns={columns}
                rowData={rowByStatusData}
                onGridReady={onGridReadyByStatus}
                defaultColumn={["Name"]}
                GoTo="/Prospects/ViewProspect"
                showLoading={loading}
                onRowSelectedData={setSelectedRowData}
                handleViewModalOpen={handleViewModalOpen}
                onDataChange={handleChildData}
                headerComponent={() => <p></p>}
                setFilteredRows={setFilteredRows}
                Source="Prospect"
              />
            </div>
          </Tabs.Tab>
          <Tabs.Tab
            eventKey="LEADSTATUS"
            title="LEADSTATUS"
            disabled={
              !(permissions.includes(LEADSTATUS) || user.Type === "SuperAdmin")
            }
          >
            <div>
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-LeadStatus">
                  Lead Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-LeadStatus"
                  id="demo-simple-select-LeadStatus"
                  label="Status"
                  name="Status"
                  value={selectedLeadStatus || ""}
                  onChange={(e) => {
                    setSelectedLeadStatus(e.target.value);
                    //console.log("Status=> " + e.target.value);
                  }}
                  onSelect={(e) => {
                    setSelectedLeadStatus(e.target.value);
                    //console.log("Status=> " + e.target.value);
                  }}
                >
                  {[...LeadStatusList.data, "ALL"].map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(
                        name,
                        [...LeadStatusList.data, "ALL"],
                        theme
                      )}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <AgGrid
                columns={columns}
                rowData={rowByLeadStatusData}
                onGridReady={onByLeadStatusGridReady}
                defaultColumn={["Name"]}
                GoTo="/Prospects/ViewProspect"
                showLoading={loadingByLead}
                onRowSelectedData={setSelectedRowData}
                onDataChange={handleChildData}
                headerComponent={() => <p></p>}
                setFilteredRows={setFilteredRows}
                Source="Prospect"
              />
            </div>
          </Tabs.Tab>
        </Tabs>
      </Panel>
      <SideDrawer
        open={openViewModal}
        onClose={handleViewModalClose}
        title="Change status"
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log(values);
            axiosPrivate
              .post("UpdateStatusReference.php", {
                UID: values.UID,
                Status: values.Status,
              })
              .then((response) => {
                const result = response.data;
                if (result.status === 1) {
                  console.log(result);
                  toaster.push(
                    <Message type="success" bordered showIcon>
                      <strong>Success!</strong> {result?.Success}
                    </Message>
                  );
                }
                if (result.status === 0) {
                  toaster.push(
                    <Message type="error" bordered showIcon>
                      <strong>Error!</strong> {result.Error}
                    </Message>
                  );
                }
                setSubmitting(false);
                resetForm();
              })
              .catch((error) => {
                console.error("Error submitting data:", error);
                setSubmitting(false);
              });
            console.log(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            isValidating,
            isSubmitting,
            touched,
            setFieldValue,
            handleBlur,
            handleChange,
          }) => {
            console.log(errors);
            console.log(values);
            return (
              <form>
                <div>
                  <div className="row">
                    <Grid fluid style={{ padding: 4 }}>
                      <Row className="show-grid">
                        <Col xs={24} sm={24} md={24}>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <FormControl
                              variant="standard"
                              sx={{ minWidth: 120 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Status"
                                name="Status"
                                value={values?.Status || ""}
                                onChange={handleChange}
                                error={touched.Status && Boolean(errors.Status)}
                                helperText={touched.Status && errors.Status}
                              >
                                {Status.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, Status, theme)}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Row>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                  &nbsp; &nbsp;
                  <>
                    <LoadingButton
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                      loading={isValidating}
                      sx={{ my: 2 }}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </LoadingButton>
                  </>
                </div>
              </form>
            );
          }}
        </Formik>
      </SideDrawer>
    </>
  );
}
