import { useState, Fragment } from "react";
import {
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  styled,
} from "@mui/material";

import { topBarHeight } from "app/utils/constant";
import { List } from "rsuite";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";

// STYLED COMPONENTS
const SearchContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 9,
  width: "100%",
  display: "flex",
  alignItems: "center",
  height: topBarHeight,
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  "&::placeholder": {
    color: theme.palette.text.primary,
  },
}));

const SearchInput = styled("input")(({ theme }) => ({
  width: "100%",
  border: "none",
  outline: "none",
  fontSize: "1rem",
  paddingLeft: "20px",
  height: "calc(100% - 5px)",
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  "&::placeholder": { color: theme.palette.text.primary },
}));

export default function MatxSearchBox() {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  const toggle = () => setOpen(!open);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length >= 3 || isNaN(value)) {
      axiosPrivate
        .post("SearchProspect.php", { Search: value })
        .then((response) => {
          const result = response.data;
          if (result.status === 1) {
            setResults(result.Data);
          }
          if (result.status === 0) {
            setResults([]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (
      (value.length < 3 && parseInt(value) > 0 && parseInt(value) < 100) ||
      !isNaN(value)
    ) {
      axiosPrivate
        .post("SearchProspectBySrNo.php", { Search: value })
        .then((response) => {
          const result = response.data;
          if (result.status === 1) {
            setResults(result.Data);
          }
          if (result.status === 0) {
            setResults([]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setResults([]);
    }
  };

  const axiosPrivate = useAxiosPrivate();
  const handleResultClick = (id, type) => {
    setInputValue("");
    setResults([]);
    setOpen(false);
    if (type === "No") navigate(`/References/ViewReference/${id}`);
    if (type === "Yes") navigate(`/Prospects/ViewProspect/${id}`);
  };

  return (
    <Fragment>
      {!open && (
        <IconButton onClick={toggle}>
          <Icon sx={{ color: "text.primary" }}>search</Icon>
        </IconButton>
      )}

      {open && (
        <SearchContainer>
          <SearchInput
            type="text"
            placeholder="Search here by Name/Village/Phone/SrNo"
            autoFocus
            onChange={handleInputChange}
          />
          {results.length > 0 && (
            <Paper
              style={{
                position: "absolute",
                width: "90%",
                zIndex: 10000,
                top: 50,
                maxHeight: 400,
                overflowY: "auto",
              }}
            >
              <List>
                {results.map((result) => (
                  <ListItem
                    style={{ cursor: "pointer" }}
                    key={result.id}
                    onClick={() =>
                      handleResultClick(result.UniqueID, result.IsProspect)
                    }
                  >
                    <ListItemText primary={result.title} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
          <IconButton onClick={toggle} sx={{ mx: 2, verticalAlign: "middle" }}>
            <Icon sx={{ color: "text.primary" }}>close</Icon>
          </IconButton>
        </SearchContainer>
      )}
    </Fragment>
  );
}
