import CssBaseline from "@mui/material/CssBaseline";
import "rsuite/dist/rsuite.min.css";
import "./App.css";
import { MatxTheme } from "./components";
// ALL CONTEXTS
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
// ROUTES
import "../fake-db";
import secureRoutes from "./secureRoutes";

export default function App() {
  return (
    <SettingsProvider>
      <AuthProvider>
        <MatxTheme>
          <CssBaseline />
          {/* {content} */}
          {secureRoutes()}
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  );
}
