import React, { useState } from "react";
import {
  Col,
  Divider,
  Grid,
  Message,
  Notification,
  Row,
  useToaster,
} from "rsuite";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import PlusIcon from "@rsuite/icons/Plus";
import { Container, Content } from "rsuite";

import { DeleteForever } from "@mui/icons-material";
import Header from "app/components/Header";
import { Input, InputGroup, List, Panel, Tooltip, Whisper } from "rsuite";

import useAuth from "app/hooks/useAuth";

import { ellipsify } from "functions";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { ADD_INPUT, DELETE_INPUT } from "Permissions";
export default function SideInputs() {
  const axiosPrivate = useAxiosPrivate();
  const toaster = useToaster();
  const [casteList, setCasteList] = React.useState([{ label: "Test" }]);
  const [villageList, setVillageList] = React.useState([]);
  const [workTypeList, setWorkTypeList] = React.useState([]);
  const [tehsilList, setTehsilList] = React.useState([]);
  const [districtList, setDistrictList] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [professionList, setProfessionList] = React.useState([]);
  const [branchList, setBranchList] = React.useState([]);
  const [familyList, setFamilyList] = React.useState([]);
  const [AgencyCodeList, setAgencyCodeList] = React.useState([]);
  const [ServiceTypeList, setServiceTypeList] = React.useState([]);
  const [cashierList, setCashierList] = React.useState([]);
  const [paymentByList, setPaymentByList] = React.useState([]);
  const [salutationList, setSalutationList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [purposeList, setPurposeList] = React.useState([]);
  const [purpose, setPurpose] = useState("");
  const [category, setCategory] = useState("");
  const [salutation, setSalutation] = useState("");
  const [paymentBy, setPaymentBy] = useState("");
  const [cashier, setCashier] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [agencyCode, setAgencyCode] = useState("");
  const [family, setFamily] = useState("");
  const [caste, setCaste] = useState("");
  const [workType, setWorkType] = useState("");
  const [village, setVillage] = useState("");
  const [tehsil, setTehsil] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [branch, setBranch] = useState("");
  const [profession, setProfession] = useState("");
  const { permissions, user } = useAuth();
  const getCasteList = () => {
    axiosPrivate
      .post("/LoadCasteList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.UniqueID,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setCasteList(data);
          }
        }
        if (
          response &&
          (response["status"] === 401 || response["status"] === 403)
        ) {
          //console.log(result.Data);
          setCasteList([]);
        }
      })
      .catch((e) => {
        setCasteList([]);
      });
  };

  const getVillageList = () => {
    axiosPrivate
      .post("/LoadVillageList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setVillageList(data);
          }
        }
        if (
          response &&
          (response["status"] === 401 || response["status"] === 403)
        ) {
          //console.log(result.Data);
          setVillageList([]);
        }
      })
      .catch((e) => {
        setVillageList([]);
      });
  };

  const getTehsilList = () => {
    axiosPrivate
      .post("/LoadTehsilList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setTehsilList(data);
          }
        }
        if (
          response &&
          (response["status"] === 401 || response["status"] === 403)
        ) {
          //console.log(result.Data);
          setTehsilList([]);
        }
      })
      .catch((e) => {
        setTehsilList([]);
      });
  };
  const getDistrictList = () => {
    axiosPrivate
      .post("/LoadDistrictList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setDistrictList(data);
          }
        }
        if (
          response &&
          (response["status"] === 401 || response["status"] === 403)
        ) {
          //console.log(result.Data);
          setDistrictList([]);
        }
      })
      .catch((e) => {
        setDistrictList([]);
      });
  };

  const getStateList = () => {
    axiosPrivate
      .post("/LoadStateList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setStateList(data);
          }
        }
        if (
          response &&
          (response["status"] === 401 || response["status"] === 403)
        ) {
          //console.log(result.Data);
          setStateList([]);
        }
      })
      .catch((e) => {
        setStateList([]);
      });
  };

  const getBranchList = () => {
    axiosPrivate
      .post("/LoadBranchList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.UniqueID,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setBranchList(data);
          }
        }
        if (
          response &&
          (response["status"] === 401 || response["status"] === 403)
        ) {
          //console.log(result.Data);
          setBranchList([]);
        }
      })
      .catch((e) => {
        setBranchList([]);
      });
  };
  const getProfessionList = () => {
    axiosPrivate
      .post("/LoadProfessionList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setProfessionList(data);
          }
        }
        if (
          response &&
          (response["status"] === 401 || response["status"] === 403)
        ) {
          //console.log(result.Data);
          setProfessionList([]);
        }
      })
      .catch((e) => {
        setProfessionList([]);
      });
  };
  const getWorktypeList = () => {
    axiosPrivate
      .post("/LoadWorkTypeList.php")
      .then((response) => {
        const result = response.data;

        if (result.status === 1) {
          const data = result.Data.map((item) => ({
            id: item.SrNo,
            label: item.Name,
            value: item.Name,
          }));
          // console.log(data);
          setWorkTypeList(data);
        } else {
          setWorkTypeList([]);
        }
      })
      .catch((e) => {
        setWorkTypeList([]);
      });
  };
  const getFamilyList = () => {
    axiosPrivate
      .post("/LoadFamilyList.php")
      .then((response) => {
        const result = response.data;
        if (response && response["status"] === 200) {
          if (result.status === 1) {
            const data = result.Data.map((item) => ({
              id: item.SrNo,
              label: item.Name,
              value: item.Name,
            }));
            // console.log(data);
            setFamilyList(data);
          }
        }
        if (
          response &&
          (response["status"] === 401 || response["status"] === 403)
        ) {
          //console.log(result.Data);
          setFamilyList([]);
        }
      })
      .catch((e) => {
        setFamilyList([]);
      });
  };
  const getAgencyCodeList = () => {
    axiosPrivate
      .post("/LoadAgencyCode.php")
      .then((response) => {
        const result = response.data;

        if (result.status === 1) {
          const data = result.Data.map((item) => ({
            id: item.SrNo,
            label: item.Name,
            value: item.Name,
          }));
          // console.log(data);
          setAgencyCodeList(data);
        } else {
          setAgencyCodeList([]);
        }
      })
      .catch((e) => {
        setAgencyCodeList([]);
      });
  };
  const getServiceTypeList = () => {
    axiosPrivate
      .post("/LoadServiceType.php")
      .then((response) => {
        const result = response.data;

        if (result.status === 1) {
          const data = result.Data.map((item) => ({
            id: item.SrNo,
            label: item.Name,
            value: item.Name,
          }));
          // console.log(data);
          setServiceTypeList(data);
        } else {
          setServiceTypeList([]);
        }
      })
      .catch((e) => {
        setServiceTypeList([]);
      });
  };
  const getCashierList = () => {
    axiosPrivate
      .post("/LoadCashier.php")
      .then((response) => {
        const result = response.data;

        if (result.status === 1) {
          const data = result.Data.map((item) => ({
            id: item.SrNo,
            label: item.Name,
            value: item.Name,
          }));
          // console.log(data);
          setCashierList(data);
        } else {
          setCashierList([]);
        }
      })
      .catch((e) => {
        setCashierList([]);
      });
  };
  const getPaymentByList = () => {
    axiosPrivate
      .post("/LoadPaymentBy.php")
      .then((response) => {
        const result = response.data;

        if (result.status === 1) {
          const data = result.Data.map((item) => ({
            id: item.SrNo,
            label: item.Name,
            value: item.Name,
          }));
          // console.log(data);
          setPaymentByList(data);
        } else {
          setPaymentByList([]);
        }
      })
      .catch((e) => {
        setPaymentByList([]);
      });
  };
  const getSalutationList = () => {
    axiosPrivate
      .post("/LoadSalutationList.php")
      .then((response) => {
        const result = response.data;

        if (result.status === 1) {
          const data = result.Data.map((item) => ({
            id: item.SrNo,
            label: item.Name,
            value: item.Name,
          }));
          // console.log(data);
          setSalutationList(data);
        } else {
          setSalutationList([]);
        }
      })
      .catch((e) => {
        setSalutationList([]);
      });
  };
  const getCategoryList = () => {
    axiosPrivate
      .post("/LoadCategoryList.php")
      .then((response) => {
        const result = response.data;

        if (result.status === 1) {
          const data = result.Data.map((item) => ({
            id: item.SrNo,
            label: item.Name,
            value: item.Name,
          }));
          // console.log(data);
          setCategoryList(data);
        } else {
          setCategoryList([]);
        }
      })
      .catch((e) => {
        setCategoryList([]);
      });
  };
  const getPurposeList = () => {
    axiosPrivate
      .post("/LoadPurposeList.php")
      .then((response) => {
        const result = response.data;

        if (result.status === 1) {
          const data = result.Data.map((item) => ({
            id: item.SrNo,
            label: item.Name,
            value: item.Name,
          }));
          // console.log(data);
          setPurposeList(data);
        } else {
          setPurposeList([]);
        }
      })
      .catch((e) => {
        setPurposeList([]);
      });
  };
  const addPurpose = async () => {
    if (!purpose) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        Purpose: purpose,
      };
      const response = await axiosPrivate.post("/AddPurpose.php", datatosend);
      const result = response.data;
      //console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setPurpose("");
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      getPurposeList();
    }
  };
  const addCategory = async () => {
    if (!category) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        Category: category,
      };
      const response = await axiosPrivate.post("/AddCategory.php", datatosend);
      const result = response.data;
      //console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setCategory("");
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      getCategoryList();
    }
  };
  const addSalutation = async () => {
    if (!salutation) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        Salutation: salutation,
      };
      const response = await axiosPrivate.post(
        "/AddSalutation.php",
        datatosend
      );
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setSalutation("");
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      getSalutationList();
    }
  };
  const addNewPaymentBy = async () => {
    if (!paymentBy) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        PaymentBy: paymentBy,
      };
      const response = await axiosPrivate.post("/AddPaymentBy.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setPaymentBy("");
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    } finally {
      getPaymentByList();
    }
  };
  const addNewCashier = async () => {
    if (!cashier) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        Cashier: cashier,
      };
      const response = await axiosPrivate.post("/AddCashier.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setCashier("");
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    } finally {
      getCashierList();
    }
  };
  const addNewFamily = async () => {
    if (!family) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        family,
      };
      const response = await axiosPrivate.post("/AddFamily.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setFamily("");
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    } finally {
      getFamilyList();
    }
  };
  const addnewCaste = async () => {
    if (!caste) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        caste,
      };
      const response = await axiosPrivate.post("/AddCaste.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
        setCaste("");

        getCasteList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const addnewVillage = async () => {
    if (!village) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        village,
      };
      const response = await axiosPrivate.post("/AddVillage.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setVillage("");
        getVillageList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const addnewTehsil = async () => {
    if (!tehsil) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        tehsil,
      };
      const response = await axiosPrivate.post("/AddTehsil.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setTehsil("");
        getTehsilList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const addnewDistrict = async () => {
    if (!district) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        district,
      };
      const response = await axiosPrivate.post("/AddDistrict.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setDistrict("");
        getDistrictList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const addnewState = async () => {
    if (!state) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        state,
      };
      const response = await axiosPrivate.post("/AddState.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setState("");
        getStateList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const addnewProfession = async () => {
    if (!profession) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        profession,
      };
      const response = await axiosPrivate.post(
        "/AddProfession.php",
        datatosend
      );
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setProfession("");
        refershallDropdown();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const addnewWorkType = async () => {
    if (!workType) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        WorkType: workType,
      };
      const response = await axiosPrivate.post("/AddWorkType.php", datatosend);
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setWorkType("");
        refershallDropdown();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const addnewBranch = async () => {
    if (!branch) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      // const { tehsil, district, state } = addCustomerData;

      const datatosend = {
        Branch: branch,
      };
      const response = await axiosPrivate.post(
        "/AddBranchOffice.php",
        datatosend
      );
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setBranch("");
        getBranchList();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const addnewAgencyCode = async () => {
    if (!agencyCode) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        AgencyCode: agencyCode,
      };
      const response = await axiosPrivate.post(
        "/AddAgencyCode.php",
        datatosend
      );
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setAgencyCode("");
        refershallDropdown();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const addnewServiceType = async () => {
    if (!serviceType) {
      toaster.push(
        <Notification type="error" header="Error">
          {`This Fields is required.`}
        </Notification>,
        { placement: "topEnd" },
        { duration: 1000 }
      );
      return;
    }
    try {
      const datatosend = {
        ServiceType: serviceType,
      };
      const response = await axiosPrivate.post(
        "/AddServiceType.php",
        datatosend
      );
      const result = response.data;
      console.log(response);
      if (result.status === 1) {
        toaster.push(
          <Notification type="success" header="Success">
            {`Added successfully`}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );

        setServiceType("");
        refershallDropdown();
      }

      if (result.status === 0) {
        toaster.push(
          <Notification type="error" header="Error">
            {result.Error}
          </Notification>,
          { placement: "topEnd" },
          { duration: 1000 }
        );
      }
    } catch (err) {
      console.error(err);
      //navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const refershallDropdown = () => {
    getCasteList();
    getVillageList();
    getTehsilList();
    getDistrictList();
    getStateList();
    getBranchList();
    getProfessionList();
    getFamilyList();
    getWorktypeList();
    getFamilyList();
    getAgencyCodeList();
    getServiceTypeList();
    getCashierList();
    getPaymentByList();
    getSalutationList();
    getCategoryList();
    getPurposeList();
  };
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      refershallDropdown();
    }

    return () => {
      isMounted = false;
    };
  }, []);
  const deleteitem = (id, value, source) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    id,
                    value,
                    source,
                  };
                  const response = await axiosPrivate.post(
                    "/RemoveItem.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success">{result.Success}</Message>
                    );
                    refershallDropdown();
                    onClose();
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    onClose();
                  }
                } catch (err) {
                  console.error(err);
                } finally {
                  onClose();
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const deleteBranch = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: id,
                  };
                  const response = await axiosPrivate.post(
                    "/RemoveBranch.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    refershallDropdown();
                    toaster.push(
                      <Message type="success">{result.Success}</Message>
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Notification type="error" header="Error">
                        {`Error occured`}
                      </Notification>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  return (
    <>
      <Box
        component="section"
        sx={{
          p: 1,
          border: "0.5px dashed #ddd",
          width: "100%",
          backgroundColor: "#FEFAF6",
        }}
      >
        <Stack spacing={1}>
          <Header headerText="Manage Inputs"></Header>

          <Container>
            <Content
              style={{
                flex: 1,
                width: "100%",
                maxHeight: 650,
                borderRadius: 10,
                border: "1px dashed #ddd",
              }}
            >
              <Grid fluid style={{ padding: 10 }}>
                <Row className="show-grid" gutter={2}>
                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={caste} onChange={setCaste} />
                        <InputGroup.Button
                          onClick={addnewCaste}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Caste" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {casteList.map((item, index) => (
                              <List.Item
                                onClick={() => setCaste(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    href="#"
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(
                                        item.id,
                                        item.value,
                                        "InsuranceCo"
                                      )
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={village} onChange={setVillage} />
                        <InputGroup.Button
                          onClick={addnewVillage}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Village" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {villageList.map((item, index) => (
                              <List.Item
                                onClick={() => setVillage(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(
                                        item.id,
                                        item.value,
                                        "Villages"
                                      )
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={tehsil} onChange={setTehsil} />
                        <InputGroup.Button
                          onClick={addnewTehsil}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Tehsil" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {tehsilList.map((item, index) => (
                              <List.Item
                                onClick={() => setTehsil(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(item.id, item.value, "Tehsil")
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={district} onChange={setDistrict} />
                        <InputGroup.Button
                          onClick={addnewDistrict}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="District" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {districtList.map((item, index) => (
                              <List.Item
                                onClick={() => setDistrict(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(
                                        item.id,
                                        item.value,
                                        "District"
                                      )
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={state} onChange={setState} />
                        <InputGroup.Button
                          onClick={addnewState}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="State" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {stateList.map((item, index) => (
                              <List.Item
                                onClick={() => setState(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(item.id, item.value, "State")
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={branch} onChange={setBranch} />
                        <InputGroup.Button
                          onClick={addnewBranch}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Branch" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {branchList.map((item, index) => (
                              <List.Item
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteBranch(item.id)}
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={profession} onChange={setProfession} />
                        <InputGroup.Button
                          onClick={addnewProfession}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Profession List" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {professionList.map((item, index) => (
                              <List.Item
                                onClick={() => setProfession(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(
                                        item.id,
                                        item.value,
                                        "Profession"
                                      )
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={workType} onChange={setWorkType} />
                        <InputGroup.Button
                          onClick={addnewWorkType}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Work Type List" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {workTypeList.map((item, index) => (
                              <List.Item
                                onClick={() => setWorkType(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(
                                        item.id,
                                        item.value,
                                        "WorkType"
                                      )
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={family} onChange={setFamily} />
                        <InputGroup.Button
                          onClick={addNewFamily}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Family List" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {familyList.map((item, index) => (
                              <List.Item
                                onClick={() => setFamily(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  textTransform: "capitalize",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p style={{ textTransform: "capitalize" }}>
                                    {ellipsify(item.value)}
                                  </p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(item.id, item.value, "Family")
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={agencyCode} onChange={setAgencyCode} />
                        <InputGroup.Button
                          onClick={addnewAgencyCode}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Self LIC Agencies" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {AgencyCodeList.map((item, index) => (
                              <List.Item
                                onClick={() => setAgencyCode(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(
                                        item.id,
                                        item.value,
                                        "AgencyCode"
                                      )
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={serviceType} onChange={setServiceType} />
                        <InputGroup.Button
                          onClick={addnewServiceType}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Service Type" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {ServiceTypeList.map((item, index) => (
                              <List.Item
                                onClick={() => setServiceType(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(
                                        item.id,
                                        item.value,
                                        "ServiceType"
                                      )
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={cashier} onChange={setCashier} />
                        <InputGroup.Button
                          onClick={addNewCashier}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Cashier" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {cashierList.map((item, index) => (
                              <List.Item
                                onClick={() => setCashier(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(item.id, item.value, "Cashier")
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={paymentBy} onChange={setPaymentBy} />
                        <InputGroup.Button
                          onClick={addNewPaymentBy}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Payment By" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {paymentByList.map((item, index) => (
                              <List.Item
                                onClick={() => setPaymentBy(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(
                                        item.id,
                                        item.value,
                                        "PaymentBy"
                                      )
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={salutation} onChange={setSalutation} />
                        <InputGroup.Button
                          onClick={addSalutation}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Salutation" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {salutationList.map((item, index) => (
                              <List.Item
                                onClick={() => setSalutation(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(
                                        item.id,
                                        item.value,
                                        "Salutation"
                                      )
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={category} onChange={setCategory} />
                        <InputGroup.Button
                          onClick={addCategory}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Ref Category" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {categoryList.map((item, index) => (
                              <List.Item
                                onClick={() => setCategory(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(
                                        item.id,
                                        item.value,
                                        "Category"
                                      )
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <InputGroup style={{ width: 300, marginBottom: 10 }}>
                        <Input value={purpose} onChange={setPurpose} />
                        <InputGroup.Button
                          onClick={addPurpose}
                          disabled={
                            !(
                              permissions.includes(ADD_INPUT) ||
                              user.Type === "SuperAdmin"
                            )
                          }
                        >
                          <PlusIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <div style={{ marginTop: 5, width: 300 }}>
                        <Panel header="Ref Purpose" bordered>
                          <List
                            size="sm"
                            style={{ maxHeight: 180, overflow: "auto" }}
                          >
                            {purposeList.map((item, index) => (
                              <List.Item
                                onClick={() => setPurpose(item.value)}
                                key={item.id}
                                index={index}
                                style={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{`${item.value}`}</Tooltip>}
                                >
                                  <p>{ellipsify(item.value)}</p>
                                </Whisper>
                                {(permissions.includes(DELETE_INPUT) ||
                                  user.Type === "SuperAdmin") && (
                                  <a
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteitem(item.id, item.value, "Purpose")
                                    }
                                  >
                                    <DeleteForever />
                                  </a>
                                )}
                              </List.Item>
                            ))}
                          </List>
                        </Panel>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </Content>
          </Container>
        </Stack>
      </Box>
    </>
  );
}
