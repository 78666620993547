import * as React from "react";

import Stack from "@mui/material/Stack";

import useAuth from "app/hooks/useAuth";
import { DELETE_POLICY, EDIT_POLICY, VIEW_POLICYSERVICE } from "Permissions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  Autocomplete,
  InputLabel,
  FormControl,
  TextField,
  useTheme,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { Field, Formik } from "formik";
import "moment/locale/en-in";
import { Button, Col, Grid, Heading, Row } from "rsuite";

import { Uploader, Message, Loader, useToaster, Button as Btn } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";

import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import {
  Delete,
  DeleteForever,
  Image,
  PictureAsPdf,
  RemoveRedEye,
  RestoreFromTrash,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import useAPINew from "app/hooks/useAPINew";
import { useProps } from "./PolicyProvider";

import { styled, lighten, darken } from "@mui/system";
const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const today = new Date();
today.setHours(0, 0, 0, 0);

const validationSchema = yup.object({
  PolicyNo: yup
    .string()
    .matches(/^\d{9}$/, "Valid 9 digits policy no required")
    .nullable(true),
});

export default function EditPolicy() {
  const { user, permissions } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { UID } = useParams();
  const [isDeleted, setIsDeleted] = React.useState("0");
  //const uploader = React.useRef();
  const [docs, setDocs] = React.useState([]);
  const [data, setData] = React.useState({});
  const [familyList, setFamilyList] = React.useState([]);
  const toaster = useToaster();
  //const uploader = React.useRef();
  const [fileList1, setFileList1] = React.useState([]);
  const [policyServices, setPolicyServices] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const ReferenceList = useAPINew("GetReferenceList.php");
  const AgencyCodeList = useAPINew("GetAgencyCodeList.php");
  const UsersList = useAPINew("GetListOfUsersToAssign.php");
  const options = (UsersList.error ? [null] : UsersList.data).map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = React.useState({});
  const initialState = {
    EditMode: false,
    Attachment: false,
  };
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "EDITABLE":
        return { ...initialState, EditMode: true };
      case "LOCKED":
        return { ...initialState, EditMode: false, Attachment: true };
      case "DONE":
        return { ...initialState, Attachment: false };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getDahiyaMitra();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const { rowFiltereddata, updateIndexValue, index, setIndex } = useProps();
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getDahiyaMitra();
      //console.log(rowFiltereddata);
      updateIndexValue(rowFiltereddata.indexOf(UID));
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getDahiyaMitra();
    }
    return () => {
      isSubscribed = false;
    };
  }, [index]);

  const goToPrevious = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    console.log(index);
    console.log(rowFiltereddata[index]);
    navigate(`/Policy/View/${rowFiltereddata[index > 0 ? index - 1 : index]}`);
  };

  const goToNext = () => {
    setIndex((prevIndex) =>
      prevIndex < rowFiltereddata.length - 1 ? prevIndex + 1 : prevIndex
    );
    console.log(index);
    console.log(rowFiltereddata[index]);
    navigate(
      `/Policy/View/${
        rowFiltereddata[index < rowFiltereddata.length - 1 ? index + 1 : index]
      }`
    );
  };
  const RefList = useAPINew("GetRefDahiyaMitraList.php");
  const getDahiyaMitra = async () => {
    try {
      const responce = await axiosPrivate.post("GetPolicyByID.php", {
        UID,
      });

      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        GetFamilyDetails(result.Data[0].ReferenceID);
        const newData = {
          ...result.Data[0],

          AgencyCode: {
            value: result.Data[0].AgencyCode,
            title: result.Data[0].AgencyCode,
          },
          FamilyMemberID: {
            value: result.Data[0].FamilyMemberID,
            title: result.Data[0].FamilyMember,
          },
          ReferenceID: {
            value: result.Data[0].ReferenceID,
            title: result.Data[0].Reference,
          },
          RefBy:
            result.Data[0].RefBy === ""
              ? null
              : {
                  value: result.Data[0].RefBy,
                  title: result.Data[0].RefBy,
                },
          DOC:
            result.Data[0].DOC === "0000-00-00" || result.Data[0].DOC === null
              ? null
              : result.Data[0].DOC,
          CompletionDate:
            result.Data[0].CompletionDate === "0000-00-00" ||
            result.Data[0].CompletionDate === null
              ? null
              : result.Data[0].CompletionDate,
          AssignedTo:
            result.Data[0].AssignedTo === ""
              ? null
              : {
                  value: result.Data[0].AssignedTo,
                  title: result.Data[0].AssignedToName,
                },
        };
        setInitialValues(newData);

        setFileInfo(
          `https://backend.dahiyainsurance.com/Upload/profile/${result.Data[0].Picture}`
        );
        setDocs(result.Docs);
        setData({ ...data, ...result.Data[0] });
        setIsDeleted(result.Data[0].Deleted);
        setPolicyServices(result.PolicyServices);
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };

  function GetFamilyDetails(FamilyHeadID) {
    axiosPrivate
      .post("GetRelatedFamilyList.php", { UID: FamilyHeadID })
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          setFamilyList(result.Data);
        }
        if (result.status === 0) {
          setFamilyList([{ value: "", title: "" }]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleAttachment() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`Are you done with attaching documents?`}</p>
            <button
              onClick={() => {
                dispatch({ type: "DONE" });
                onClose();
                setFileList1([]);
              }}
            >
              Yes
            </button>
            <button onClick={onClose}>No</button>
          </div>
        );
      },
    });
  }
  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyDoc.php",
                    datatosend
                  );
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID !== UID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  const Remove = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicy.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    navigate("/Policy/List");
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const RemovePermanently = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this details for forever.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyPermanently.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  const Restore = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to restore this details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RestorePolicy.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    navigate("/Policy/List");
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  onClose();
                }
              }}
            >
              Yes, Restore it!
            </button>
          </div>
        );
      },
    });
  };

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.9,
            }}
          >
            {`${state.EditMode ? "Edit" : "View"} Policy Details`}
          </Heading>
          <Stack direction="row" spacing={2} style={{ flex: 0.1 }}>
            {(permissions.includes(DELETE_POLICY) ||
              user.Type === "SuperAdmin") && (
              <>
                {isDeleted === "0" && (
                  <Btn
                    color="red"
                    appearance="link"
                    onClick={Remove}
                    sx={{ my: 2 }}
                    startIcon={<Delete />}
                  >
                    {"Delete"}
                  </Btn>
                )}
                {isDeleted === "1" && (
                  <>
                    <Btn
                      color="green"
                      appearance="link"
                      onClick={Restore}
                      sx={{ my: 2 }}
                      startIcon={<RestoreFromTrash />}
                    >
                      {"Restore"}
                    </Btn>
                    <Btn
                      color="red"
                      appearance="link"
                      onClick={RemovePermanently}
                      sx={{ my: 2 }}
                      startIcon={<DeleteForever />}
                    >
                      {"Delete Permanentaly"}
                    </Btn>
                  </>
                )}
              </>
            )}
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={16}>
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  axiosPrivate
                    .post("EditPolicy.php", {
                      ...values,
                      AgencyCode: values.AgencyCode.value,
                      FamilyMemberID: values.FamilyMemberID.value,
                      ReferenceID: values.ReferenceID.value,
                      AssignedTo: values.AssignedTo?.value,
                      DOC: values?.DOC
                        ? moment(values.DOC).format("YYYY-MM-DD")
                        : null,
                      CompletionDate: values?.CompletionDate
                        ? moment(values.CompletionDate).format("YYYY-MM-DD")
                        : null,
                    })
                    .then((response) => {
                      const result = response.data;
                      if (result.status === 1) {
                        console.log(result);
                        toaster.push(
                          <Message type="success" bordered showIcon>
                            <strong>Success!</strong> {result?.Success}
                          </Message>
                        );
                        dispatch({ type: "LOCKED" });
                      }
                      if (result.status === 0) {
                        toaster.push(
                          <Message type="error" bordered showIcon>
                            <strong>Error!</strong> {result.Error}
                          </Message>
                        );
                      }
                      setSubmitting(false);
                      // resetForm();
                    })
                    .catch((error) => {
                      console.error("Error submitting data:", error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValidating,
                  setErrors,
                  setTouched,
                }) => (
                  <form className="target">
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading
                        level={6}
                        style={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Reference ID
                      </Heading>
                    </Row>
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <Field
                          name="ReferenceID"
                          readOnly={!state.EditMode}
                          component={Autocomplete}
                          key="ID"
                          options={
                            ReferenceList?.error
                              ? [{ value: "", title: "" }]
                              : ReferenceList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 400 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "ReferenceID",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                            GetFamilyDetails(value.value);
                            setFieldValue("FamilyMemberID", {
                              value: "",
                              title: "",
                            });
                          }}
                          value={
                            Boolean(values.ReferenceID)
                              ? values.ReferenceID
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="ReferenceID"
                              label="ReferenceID"
                              variant="standard"
                              error={
                                touched.ReferenceID &&
                                Boolean(errors.ReferenceID)
                              }
                              helperText={
                                touched.ReferenceID && errors.ReferenceID
                              }
                            />
                          )}
                        />
                        &nbsp;
                        {values?.ReferenceID?.value && (
                          <Button
                            appearance="link"
                            onClick={() => {
                              console.log(values);
                              navigate(
                                "/Prospects/ViewProspect/" +
                                  values?.ReferenceID?.value
                              );
                            }}
                          >
                            <RemoveRedEye />
                          </Button>
                        )}
                        &nbsp; &nbsp;
                        <Field
                          name="FamilyMemberID"
                          readOnly={!state.EditMode}
                          component={Autocomplete}
                          key="ID"
                          options={
                            familyList?.length > 0
                              ? familyList
                              : [{ value: "", title: "" }]
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={async (_, value) => {
                            setFieldValue(
                              "FamilyMemberID",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                            try {
                              const responce = await axiosPrivate.post(
                                "GetFamilyByID.php",
                                {
                                  UID: value.value,
                                }
                              );
                              //console.log(responce);
                              const result = responce.data;
                              if (result.status === 1) {
                                setFieldValue(
                                  "DOBR",
                                  result.Data[0].DOBR === "0000-00-00" ||
                                    result.Data[0].DOBR === null
                                    ? null
                                    : new Date(result.Data[0].DOBR)
                                );
                                const currentDate = moment();
                                const birthDate = moment(
                                  result.Data[0].DOBR === "0000-00-00" ||
                                    result.Data[0].DOBR === null
                                    ? null
                                    : new Date(result.Data[0].DOBR)
                                );
                                const age = currentDate.diff(
                                  birthDate,
                                  "years"
                                );
                                if (age >= 18) {
                                  setFieldValue("MinorPolicy", "NO");
                                } else {
                                  setFieldValue("MinorPolicy", "YES");
                                }
                              }
                              if (result.status === 0) {
                                toaster.push(
                                  <Message type="error" bordered showIcon>
                                    <strong>Error!</strong> {result?.Error}
                                  </Message>
                                );
                              }
                            } catch (error) {
                              console.log(error);
                              toaster.push(
                                <Message type="error" bordered showIcon>
                                  <strong>Error!</strong> Error occured
                                </Message>
                              );
                            }
                          }}
                          value={
                            Boolean(values.FamilyMemberID)
                              ? values.FamilyMemberID
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="FamilyMemberID"
                              label="Family Member"
                              variant="standard"
                              error={
                                touched.FamilyMemberID &&
                                Boolean(errors.FamilyMemberID)
                              }
                              helperText={
                                touched.FamilyMemberID && errors.FamilyMemberID
                              }
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Policy details</Heading>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginBottom: 10, paddingBottom: 10 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <TextField
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          label="Policy Holder Name"
                          size="small"
                          variant="standard"
                          name="PolicyHolderName"
                          value={values.PolicyHolderName}
                          InputLabelProps={{
                            shrink: Boolean(values.PolicyHolderName),
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.PolicyHolderName &&
                            Boolean(errors.PolicyHolderName)
                          }
                          helperText={
                            touched.PolicyHolderName && errors.PolicyHolderName
                          }
                          style={{ width: 300 }}
                        />{" "}
                        <Field
                          disabled={true}
                          name="RefBy"
                          component={Autocomplete}
                          key="ID"
                          style={{ width: 150 }}
                          autoComplete={true}
                          autoHighlight={true}
                          options={RefList.error ? [null] : RefList.data}
                          getOptionLabel={(option) => option.title || ""}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "RefBy",
                              value || { value: "", title: "" }
                            );
                            console.log(errors);
                            console.log(values);
                          }}
                          value={
                            Boolean(values.RefBy)
                              ? values.RefBy
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="RefBy"
                              label="RefBy"
                              variant="standard"
                              error={touched.RefBy && Boolean(errors.RefBy)}
                              helperText={touched.RefBy && errors.RefBy}
                            />
                          )}
                        />
                        &nbsp;&nbsp;
                        {(values.RefBy?.value === "CUSTOMER" ||
                          values.RefBy?.value === "OTHER") && (
                          <TextField
                            disabled={true}
                            label={`${
                              values.RefBy?.value === "CUSTOMER"
                                ? "Customer"
                                : "Ref By "
                            } Name`}
                            size="small"
                            variant="standard"
                            onBlur={handleBlur}
                            name="RefByName"
                            value={values.RefByName}
                            onChange={handleChange}
                            error={
                              touched.RefByName && Boolean(errors.RefByName)
                            }
                            helperText={touched.RefByName && errors.RefByName}
                          />
                        )}
                      </Stack>
                    </Row>
                    <Row gutter={1} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <Field
                          readOnly={!state.EditMode}
                          name="AgencyCode"
                          component={Autocomplete}
                          key="ID"
                          options={
                            AgencyCodeList?.error
                              ? [{ value: "", title: "" }]
                              : AgencyCodeList.data
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 250 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "AgencyCode",
                              value || { value: "", title: "" }
                            );
                          }}
                          value={
                            Boolean(values.AgencyCode)
                              ? values.AgencyCode
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="AgencyCode"
                              label="AgencyCode"
                              variant="standard"
                              error={
                                touched.AgencyCode && Boolean(errors.AgencyCode)
                              }
                              helperText={
                                touched.AgencyCode && errors.AgencyCode
                              }
                            />
                          )}
                        />
                        &nbsp; &nbsp;
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="Completion Date"
                            variant="standard"
                            size="small"
                            name="CompletionDate"
                            format="dd-MM-yyyy"
                            style={{ width: 120 }}
                            value={values.CompletionDate}
                            onChange={(value) =>
                              setFieldValue("CompletionDate", value, true)
                            }
                            onBlur={handleBlur}
                            error={
                              touched.CompletionDate &&
                              Boolean(errors.CompletionDate)
                            }
                            helperText={
                              touched.CompletionDate && errors.CompletionDate
                            }
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <TextField
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          label="Policy No"
                          size="small"
                          variant="standard"
                          name="PolicyNo"
                          InputLabelProps={{
                            shrink: Boolean(values.PolicyNo),
                          }}
                          value={values.PolicyNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.PolicyNo && Boolean(errors.PolicyNo)}
                          helperText={touched.PolicyNo && errors.PolicyNo}
                          style={{ width: 120 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginTop: 10 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            readOnly={!state.EditMode}
                            label="DOC"
                            variant="standard"
                            size="small"
                            name="DOC"
                            format="dd-MM-yyyy"
                            value={values.DOC}
                            onChange={(value) =>
                              setFieldValue("DOC", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.DOC && Boolean(errors.DOC)}
                            helperText={touched.DOC && errors.DOC}
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <TextField
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          label="Paying Term"
                          size="small"
                          variant="standard"
                          name="PayingTerm"
                          InputLabelProps={{
                            shrink: Boolean(values.PayingTerm),
                          }}
                          value={values.PayingTerm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.PayingTerm && Boolean(errors.PayingTerm)
                          }
                          helperText={touched.PayingTerm && errors.PayingTerm}
                          style={{ width: 200 }}
                        />{" "}
                        &nbsp; &nbsp;
                        <TextField
                          InputProps={{
                            readOnly: !state.EditMode,
                          }}
                          label="Sum Assured"
                          size="small"
                          variant="standard"
                          name="SumAssured"
                          value={values.SumAssured}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: Boolean(values.SumAssured),
                          }}
                          error={
                            touched.SumAssured && Boolean(errors.SumAssured)
                          }
                          helperText={touched.SumAssured && errors.SumAssured}
                          style={{ width: 200 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Premium Details</Heading>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginTop: 10 }}
                    >
                      <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          Mode
                        </InputLabel>
                        <Select
                          readOnly={!state.EditMode}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label="Mode"
                          name="Mode"
                          value={values?.Mode || ""}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "MLY-E-Nach") {
                              setFieldValue("ENachMode", "YES", true);
                            } else {
                              setFieldValue("ENachMode", "NO", true);
                            }
                          }}
                          error={touched.Mode && Boolean(errors.Mode)}
                          helperText={touched.Mode && errors.Mode}
                        >
                          {[
                            "YLY",
                            "HLY",
                            "QLY",
                            "MLY-3-ENACH",
                            "MLY-E-Nach",
                            "SINGLE",
                            "MLY-SSS",
                          ].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(
                                name,
                                [
                                  "YLY",
                                  "HLY",
                                  "QLY",
                                  "MLY-3-ENACH",
                                  "MLY-E-Nach",
                                  "SINGLE",
                                  "MLY-SSS",
                                ],
                                theme
                              )}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      &nbsp;&nbsp;
                      <TextField
                        InputProps={{
                          readOnly: !state.EditMode,
                        }}
                        label="FIRST BASIC PREMIUM (NO GST)"
                        size="small"
                        variant="standard"
                        style={{ width: 250 }}
                        name="FirstBasic"
                        value={values.FirstBasic}
                        InputLabelProps={{
                          shrink: Boolean(values.FirstBasic),
                        }}
                        onChange={(e) => {
                          handleChange(e);
                          const FirstBasic = e.target.value;
                          if (!isNaN(values.GST) && !isNaN(FirstBasic)) {
                            const val = (FirstBasic * values.GST) / 100;
                            setFieldValue("GSTAmount", val, true);
                            setFieldValue(
                              "FirstTotal",
                              +val + +FirstBasic,
                              true
                            );
                            if (values?.Mode === "YLY") {
                              setFieldValue(
                                "FirstYLYBasic",
                                FirstBasic * 1,
                                true
                              );
                            } else if (values?.Mode === "HLY") {
                              setFieldValue(
                                "FirstYLYBasic",
                                FirstBasic * 2,
                                true
                              );
                            } else if (
                              values?.Mode === "QLY" ||
                              values?.Mode === "MLY-3-ENACH"
                            ) {
                              setFieldValue(
                                "FirstYLYBasic",
                                FirstBasic * 4,
                                true
                              );
                            } else if (
                              values?.Mode === "MLY-SSS" ||
                              values?.Mode === "MLY-E-Nach"
                            ) {
                              setFieldValue(
                                "FirstYLYBasic",
                                FirstBasic * 4,
                                true
                              );
                            } else {
                              setFieldValue("FirstYLYBasic", FirstBasic, true);
                            }
                          }
                        }}
                        onBlur={handleBlur}
                        error={touched.FirstBasic && Boolean(errors.FirstBasic)}
                        helperText={touched.FirstBasic && errors.FirstBasic}
                      />{" "}
                      &nbsp;&nbsp;
                      <TextField
                        InputProps={{
                          readOnly: !state.EditMode,
                        }}
                        label="GST%"
                        size="small"
                        variant="standard"
                        style={{ width: 50 }}
                        name="GST"
                        InputLabelProps={{
                          shrink: Boolean(values.GST),
                        }}
                        value={values.GST}
                        onChange={(e) => {
                          handleChange(e);
                          const FirstBasic = e.target.value;
                          if (!isNaN(values.GST) && !isNaN(FirstBasic)) {
                            const val = Math.random(
                              (FirstBasic * values.GST) / 100
                            );
                            setFieldValue("GSTAmount", val, true);
                            setFieldValue(
                              "FirstTotal",
                              Math.random(+val + +FirstBasic),
                              true
                            );
                            if (values?.Mode === "YLY") {
                              setFieldValue(
                                "FirstYLYBasic",
                                Math.random(FirstBasic * 1),
                                true
                              );
                            } else if (values?.Mode === "HLY") {
                              setFieldValue(
                                "FirstYLYBasic",
                                Math.random(FirstBasic * 2),
                                true
                              );
                            } else if (
                              values?.Mode === "QLY" ||
                              values?.Mode === "MLY-3-ENACH"
                            ) {
                              setFieldValue(
                                "FirstYLYBasic",
                                Math.random(FirstBasic * 4),
                                true
                              );
                            } else if (
                              values?.Mode === "MLY-SSS" ||
                              values?.Mode === "MLY-E-Nach"
                            ) {
                              setFieldValue(
                                "FirstYLYBasic",
                                Math.random(FirstBasic * 12),
                                true
                              );
                            } else {
                              setFieldValue("FirstYLYBasic", FirstBasic, true);
                            }
                          }
                        }}
                        onBlur={handleBlur}
                        error={touched.GST && Boolean(errors.GST)}
                        helperText={touched.GST && errors.GST}
                      />{" "}
                      &nbsp;&nbsp;
                      <TextField
                        disabled={true}
                        label="GST Amount"
                        size="small"
                        variant="standard"
                        style={{ width: 100 }}
                        name="GSTAmount"
                        value={values.GSTAmount}
                        InputLabelProps={{
                          shrink: Boolean(values.GSTAmount),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.GSTAmount && Boolean(errors.GSTAmount)}
                        helperText={touched.GSTAmount && errors.GSTAmount}
                      />{" "}
                    </Row>
                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <TextField
                        disabled={true}
                        label="FIRST TOTAL PREMIUM (GST)"
                        size="small"
                        variant="standard"
                        style={{ width: 150 }}
                        name="FirstTotal"
                        value={values.FirstTotal}
                        InputLabelProps={{
                          shrink: Boolean(values.FirstTotal),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.FirstTotal && Boolean(errors.FirstTotal)}
                        helperText={touched.FirstTotal && errors.FirstTotal}
                      />{" "}
                      &nbsp;&nbsp;
                      <TextField
                        disabled={true}
                        label="FIRST YLY PREMIUM (BASIC)"
                        size="small"
                        variant="standard"
                        style={{ width: 180 }}
                        name="FirstYLYBasic"
                        value={values.FirstYLYBasic}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: Boolean(values.FirstYLYBasic),
                        }}
                        error={
                          touched.FirstYLYBasic && Boolean(errors.FirstYLYBasic)
                        }
                        helperText={
                          touched.FirstYLYBasic && errors.FirstYLYBasic
                        }
                      />{" "}
                      &nbsp; &nbsp;
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Other details</Heading>
                    </Row>
                    <Row
                      gutter={1}
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        alignItems: "flex-end",
                        display: "flex",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateField
                          disabled={true}
                          label="DOBR"
                          variant="standard"
                          size="small"
                          name="DOBR"
                          format="dd-MM-yyyy"
                          value={values.DOBR}
                          onChange={(value) =>
                            setFieldValue("DOBR", value, true)
                          }
                          onBlur={handleBlur}
                          error={touched.DOBR && Boolean(errors.DOBR)}
                          helperText={touched.DOBR && errors.DOBR}
                        />
                      </LocalizationProvider>
                      &nbsp; &nbsp;
                      <b>
                        {values.DOBR &&
                          `${moment().diff(
                            values.DOBR ? new Date(values.DOBR) : null,
                            "years"
                          )} Year(s)`}
                      </b>
                      &nbsp; &nbsp;
                      <FormControl variant="standard" sx={{ minWidth: 200 }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          MINOR POLICY HOLDER
                        </InputLabel>
                        <Select
                          disabled={true}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label="MinorPolicy"
                          name="MinorPolicy"
                          value={values?.MinorPolicy || ""}
                          onChange={handleChange}
                          error={
                            touched.MinorPolicy && Boolean(errors.MinorPolicy)
                          }
                          helperText={touched.MinorPolicy && errors.MinorPolicy}
                        >
                          {["YES", "NO"].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, ["YES", "NO"], theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* &nbsp;&nbsp;
                      <TextField
                        readOnly={!state.EditMode}
                        label="Official Email ID"
                        size="small"
                        variant="standard"
                        style={{ width: 200 }}
                        name="OfficialEmailID"
                        value={values.OfficialEmailID}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.OfficialEmailID &&
                          Boolean(errors.OfficialEmailID)
                        }
                        helperText={
                          touched.OfficialEmailID && errors.OfficialEmailID
                        }
                      />{" "} */}
                    </Row>
                    <Row gutter={1} style={{ marginTop: 20, marginBottom: 10 }}>
                      <FormControl variant="standard" sx={{ minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-standard-ENachMode">
                          E-NACH
                        </InputLabel>
                        <Select
                          readOnly={!state.EditMode}
                          labelId="demo-simple-select-standard-ENachMode"
                          id="demo-simple-select-standard"
                          label="E-NACH"
                          name="ENachMode"
                          value={values?.ENachMode || ""}
                          onChange={handleChange}
                          error={touched.ENachMode && Boolean(errors.ENachMode)}
                          helperText={touched.ENachMode && errors.ENachMode}
                        >
                          {["YES", "NO"].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, ["YES", "NO"], theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      &nbsp; &nbsp;
                      <FormControl variant="standard" sx={{ minWidth: 180 }}>
                        <InputLabel id="demo-simple-select-standard-PolicBondRecieved">
                          BOND RECIEVED
                        </InputLabel>
                        <Select
                          readOnly={!state.EditMode}
                          labelId="demo-simple-select-standard-PolicBondRecieved"
                          id="demo-simple-select-standard"
                          label="BOND RECIEVED"
                          name="PolicBondRecieved"
                          value={values?.PolicBondRecieved || ""}
                          onChange={handleChange}
                          error={
                            touched.PolicBondRecieved &&
                            Boolean(errors.PolicBondRecieved)
                          }
                          helperText={
                            touched.PolicBondRecieved &&
                            errors.PolicBondRecieved
                          }
                        >
                          {["YES", "NO"].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, ["YES", "NO"], theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      &nbsp; &nbsp;
                      <FormControl variant="standard" sx={{ minWidth: 180 }}>
                        <InputLabel id="demo-simple-select-standard-FormSubmittedOnline">
                          FORM ONLINE SUBMIT
                        </InputLabel>
                        <Select
                          readOnly={!state.EditMode}
                          labelId="demo-simple-select-standard-FormSubmittedOnline"
                          id="demo-simple-select-standard"
                          label="FORM ONLINE SUBMIT"
                          name="FormSubmittedOnline"
                          value={values?.FormSubmittedOnline || ""}
                          onChange={handleChange}
                          error={
                            touched.FormSubmittedOnline &&
                            Boolean(errors.FormSubmittedOnline)
                          }
                          helperText={
                            touched.FormSubmittedOnline &&
                            errors.FormSubmittedOnline
                          }
                        >
                          {["YES", "NO"].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, ["YES", "NO"], theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Row>
                    <Row gutter={1} style={{ marginTop: 20, marginBottom: 10 }}>
                      <TextField
                        InputProps={{
                          readOnly: !state.EditMode,
                        }}
                        label="VIMPRO ID"
                        size="small"
                        variant="standard"
                        style={{ width: 200 }}
                        name="VIMPROID"
                        InputLabelProps={{
                          shrink: Boolean(values.VIMPROID),
                        }}
                        value={values.VIMPROID}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.VIMPROID && Boolean(errors.VIMPROID)}
                        helperText={touched.VIMPROID && errors.VIMPROID}
                      />{" "}
                    </Row>
                    <br />
                    {user.Type === "SuperAdmin" && (
                      <>
                        <Row gutter={6} style={{ marginBottom: 10 }}>
                          <Heading level={6}>LEAD FINAL STATUS</Heading>
                        </Row>
                        <Row gutter={6} className="show-grid">
                          <Stack direction="row" spacing={1}>
                            <TextField
                              InputProps={{
                                readOnly: !state.EditMode,
                              }}
                              label="COMMISSION %"
                              size="small"
                              variant="standard"
                              InputLabelProps={{
                                shrink: Boolean(values.CommPer),
                              }}
                              style={{ width: 120 }}
                              name="CommPer"
                              value={values.CommPer}
                              onChange={(e) => {
                                handleChange(e);
                                const CommPer = e.target.value;
                                if (!isNaN(values.GST) && !isNaN(CommPer)) {
                                  const val =
                                    (CommPer * values.FirstBasic) / 100;
                                  setFieldValue(
                                    "CommOfFirstBasisPremium",
                                    val,
                                    true
                                  );
                                  setFieldValue(
                                    "NetSavingFirst",
                                    parseInt(
                                      parseFloat(val) -
                                        parseFloat(
                                          parseFloat(values.DR2) +
                                            parseFloat(values.OtherExpenses) +
                                            parseFloat(values.OtherComm)
                                        )
                                    ),
                                    true
                                  );
                                  if (values?.Mode === "YLY") {
                                    setFieldValue(
                                      "CommOfFirstBasisYLYPremium",
                                      val * 1,
                                      true
                                    );
                                    setFieldValue(
                                      "NetSavingYLY",
                                      parseInt(
                                        parseFloat(val * 1) -
                                          parseFloat(
                                            parseFloat(values.DR2) +
                                              parseFloat(values.OtherExpenses) +
                                              parseFloat(values.OtherComm)
                                          )
                                      ),
                                      true
                                    );
                                  } else if (values?.Mode === "HLY") {
                                    setFieldValue(
                                      "CommOfFirstBasisYLYPremium",
                                      val * 2,
                                      true
                                    );
                                    setFieldValue(
                                      "NetSavingYLY",
                                      parseInt(
                                        parseFloat(val * 2) -
                                          parseFloat(
                                            parseFloat(values.DR2) +
                                              parseFloat(values.OtherExpenses) +
                                              parseFloat(values.OtherComm)
                                          )
                                      ),
                                      true
                                    );
                                  } else if (
                                    values?.Mode === "QLY" ||
                                    values?.Mode === "MLY-3-ENACH"
                                  ) {
                                    setFieldValue(
                                      "CommOfFirstBasisYLYPremium",
                                      val * 4,
                                      true
                                    );
                                    setFieldValue(
                                      "NetSavingYLY",
                                      parseInt(
                                        parseFloat(val * 4) -
                                          parseFloat(
                                            parseFloat(values.DR2) +
                                              parseFloat(values.OtherExpenses) +
                                              parseFloat(values.OtherComm)
                                          )
                                      ),
                                      true
                                    );
                                  } else if (
                                    values?.Mode === "MLY-SSS" ||
                                    values?.Mode === "MLY-E-Nach"
                                  ) {
                                    setFieldValue(
                                      "CommOfFirstBasisYLYPremium",
                                      val * 12,
                                      true
                                    );
                                    setFieldValue(
                                      "NetSavingYLY",
                                      parseInt(
                                        parseFloat(val * 12) -
                                          parseFloat(
                                            parseFloat(values.DR2) +
                                              parseFloat(values.OtherExpenses) +
                                              parseFloat(values.OtherComm)
                                          )
                                      ),
                                      true
                                    );
                                  } else {
                                    setFieldValue(
                                      "CommOfFirstBasisYLYPremium",
                                      val,
                                      true
                                    );
                                    setFieldValue(
                                      "NetSavingYLY",
                                      parseInt(
                                        parseFloat(val) -
                                          parseFloat(
                                            parseFloat(values.DR2) +
                                              parseFloat(values.OtherExpenses) +
                                              parseFloat(values.OtherComm)
                                          )
                                      ),
                                      true
                                    );
                                  }
                                }
                              }}
                              onBlur={handleBlur}
                              error={touched.CommPer && Boolean(errors.CommPer)}
                              helperText={touched.CommPer && errors.CommPer}
                            />{" "}
                            &nbsp;&nbsp;
                            <TextField
                              disabled={true}
                              label="FIRST COMMISSION"
                              size="small"
                              variant="standard"
                              style={{ width: 150 }}
                              name="CommOfFirstBasisPremium"
                              value={values.CommOfFirstBasisPremium}
                              InputLabelProps={{
                                shrink: Boolean(values.CommOfFirstBasisPremium),
                              }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.CommOfFirstBasisPremium &&
                                Boolean(errors.CommOfFirstBasisPremium)
                              }
                              helperText={
                                touched.CommOfFirstBasisPremium &&
                                errors.CommOfFirstBasisPremium
                              }
                            />{" "}
                            &nbsp;&nbsp;
                            <TextField
                              disabled={true}
                              label="FIRST YLY COMMISSION"
                              size="small"
                              variant="standard"
                              style={{ width: 150 }}
                              name="CommOfFirstBasisYLYPremium"
                              value={values.CommOfFirstBasisYLYPremium}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: Boolean(
                                  values.CommOfFirstBasisYLYPremium
                                ),
                              }}
                              error={
                                touched.CommOfFirstBasisYLYPremium &&
                                Boolean(errors.CommOfFirstBasisYLYPremium)
                              }
                              helperText={
                                touched.CommOfFirstBasisYLYPremium &&
                                errors.CommOfFirstBasisYLYPremium
                              }
                            />{" "}
                          </Stack>
                        </Row>
                        &nbsp; &nbsp;
                        <Row gutter={6} style={{ marginBottom: 10 }}>
                          <Heading level={6}>Expenses</Heading>
                        </Row>
                        {/* <br /> */}
                        <Row gutter={1} style={{ marginBottom: 10 }}>
                          <TextField
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            label="DR-2"
                            size="small"
                            variant="standard"
                            style={{ width: 150 }}
                            name="DR2"
                            InputLabelProps={{
                              shrink: Boolean(values.DR2),
                            }}
                            value={values.DR2}
                            onChange={(e) => {
                              handleChange(e);
                              const val = e.target.value;
                              if (!isNaN(val)) {
                                setFieldValue(
                                  "NetSavingFirst",
                                  parseInt(
                                    parseFloat(values.CommOfFirstBasisPremium) -
                                      parseFloat(
                                        parseFloat(val) +
                                          parseFloat(values.OtherExpenses) +
                                          parseFloat(values.OtherComm)
                                      )
                                  ),
                                  true
                                );
                                setFieldValue(
                                  "NetSavingYLY",
                                  parseInt(
                                    parseFloat(
                                      values.CommOfFirstBasisYLYPremium
                                    ) -
                                      parseFloat(
                                        parseFloat(val) +
                                          parseFloat(values.OtherExpenses) +
                                          parseFloat(values.OtherComm)
                                      )
                                  ),
                                  true
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            error={touched.DR2 && Boolean(errors.DR2)}
                            helperText={touched.DR2 && errors.DR2}
                          />{" "}
                          &nbsp; &nbsp;
                          <TextField
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            label="SERVICING EXPENCES"
                            size="small"
                            variant="standard"
                            style={{ width: 150 }}
                            InputLabelProps={{
                              shrink: Boolean(values.OtherExpenses),
                            }}
                            name="OtherExpenses"
                            value={values.OtherExpenses}
                            onChange={(e) => {
                              handleChange(e);
                              const val = e.target.value;
                              if (!isNaN(val)) {
                                setFieldValue(
                                  "NetSavingFirst",
                                  parseInt(
                                    parseFloat(values.CommOfFirstBasisPremium) -
                                      parseFloat(
                                        parseFloat(values.DR2) +
                                          parseFloat(val) +
                                          parseFloat(values.OtherComm)
                                      )
                                  ),
                                  true
                                );
                                setFieldValue(
                                  "NetSavingYLY",
                                  parseInt(
                                    parseFloat(
                                      values.CommOfFirstBasisYLYPremium
                                    ) -
                                      parseFloat(
                                        parseFloat(values.DR2) +
                                          parseFloat(val) +
                                          parseFloat(values.OtherComm)
                                      )
                                  ),
                                  true
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.OtherExpenses &&
                              Boolean(errors.OtherExpenses)
                            }
                            helperText={
                              touched.OtherExpenses && errors.OtherExpenses
                            }
                          />{" "}
                          &nbsp; &nbsp;
                          <TextField
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            label="Dahiya Mitra Payout"
                            size="small"
                            variant="standard"
                            style={{ width: 150 }}
                            name="OtherComm"
                            value={values.OtherComm}
                            InputLabelProps={{
                              shrink: Boolean(values.OtherComm),
                            }}
                            onChange={(e) => {
                              handleChange(e);
                              const val = e.target.value;
                              if (!isNaN(val)) {
                                setFieldValue(
                                  "NetSavingFirst",
                                  parseInt(
                                    parseFloat(values.CommOfFirstBasisPremium) -
                                      parseFloat(
                                        parseFloat(values.DR2) +
                                          parseFloat(values.OtherExpenses) +
                                          parseFloat(val)
                                      )
                                  ),
                                  true
                                );
                                setFieldValue(
                                  "NetSavingYLY",
                                  parseInt(
                                    parseFloat(
                                      values.CommOfFirstBasisYLYPremium
                                    ) -
                                      parseFloat(
                                        parseFloat(values.DR2) +
                                          parseFloat(values.OtherExpenses) +
                                          parseFloat(val)
                                      )
                                  ),
                                  true
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.OtherComm && Boolean(errors.OtherComm)
                            }
                            helperText={touched.OtherComm && errors.OtherComm}
                          />{" "}
                        </Row>
                        <Row gutter={1} className="show-grid">
                          <TextField
                            disabled={true}
                            label="APPROX FIRST INCOME"
                            size="small"
                            variant="standard"
                            style={{ width: 200 }}
                            name="NetSavingFirst"
                            value={values.NetSavingFirst}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(values.NetSavingFirst),
                            }}
                            error={
                              touched.NetSavingFirst &&
                              Boolean(errors.NetSavingFirst)
                            }
                            helperText={
                              touched.NetSavingFirst && errors.NetSavingFirst
                            }
                          />{" "}
                          &nbsp; &nbsp;
                          <TextField
                            disabled={true}
                            label="APPROX FIRST YLY INCOME"
                            size="small"
                            variant="standard"
                            style={{ width: 200 }}
                            InputLabelProps={{
                              shrink: Boolean(values.NetSavingYLY),
                            }}
                            name="NetSavingYLY"
                            value={values.NetSavingYLY}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.NetSavingYLY &&
                              Boolean(errors.NetSavingYLY)
                            }
                            helperText={
                              touched.NetSavingYLY && errors.NetSavingYLY
                            }
                          />{" "}
                        </Row>
                        <br />
                        <Row gutter={6} style={{ marginBottom: 10 }}>
                          <Heading level={6}>Feedback</Heading>
                        </Row>
                        <Row gutter={1} style={{ marginBottom: 10 }}>
                          <FormControl
                            variant="standard"
                            sx={{ minWidth: 180 }}
                          >
                            <InputLabel id="demo-simple-select-standard-Responce">
                              Responce
                            </InputLabel>
                            <Select
                              readOnly={!state.EditMode}
                              labelId="demo-simple-select-standard-Responce"
                              id="demo-simple-select-Responce"
                              label="Responce"
                              name="Responce"
                              value={values?.Responce || ""}
                              onChange={handleChange}
                              error={
                                touched.Responce && Boolean(errors.Responce)
                              }
                              helperText={touched.Responce && errors.Responce}
                            >
                              {["EXCELLENT", "BETTER", "GOOD", "NEGATIVE"].map(
                                (name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(
                                      name,
                                      [
                                        "EXCELLENT",
                                        "BETTER",
                                        "GOOD",
                                        "NEGATIVE",
                                      ],
                                      theme
                                    )}
                                  >
                                    {name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Row>
                      </>
                    )}
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Permission</Heading>
                    </Row>
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={0}>
                        {user.Type === "SuperAdmin" && (
                          <Field
                            readOnly={!state.EditMode}
                            name="AssignedTo"
                            component={Autocomplete}
                            key="ID"
                            options={options.sort(
                              (a, b) =>
                                -b.firstLetter.localeCompare(a.firstLetter)
                            )}
                            groupBy={(option) => option.UserType}
                            sx={{ width: 300 }}
                            renderGroup={(params) => (
                              <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                              </li>
                            )}
                            getOptionLabel={(option) => option?.title || ""}
                            style={{ width: 200 }}
                            onBlur={handleBlur} // so formik can see the forms touched state
                            onChange={(_, value) => {
                              setFieldValue(
                                "AssignedTo",
                                value || { value: "", title: "" }
                              );
                              console.log(values);
                            }}
                            value={
                              Boolean(values.AssignedTo)
                                ? values.AssignedTo
                                : { value: "", title: "" }
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="AssignedTo"
                                label="Assigned To"
                                variant="standard"
                                error={
                                  touched.AssignedTo &&
                                  Boolean(errors.AssignedTo)
                                }
                                helperText={
                                  touched.AssignedTo && errors.AssignedTo
                                }
                              />
                            )}
                          />
                        )}
                      </Stack>
                    </Row>
                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Stack direction="row" spacing={4}>
                        {state.EditMode ? (
                          <>
                            <LoadingButton
                              disabled={isSubmitting}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              onClick={handleSubmit}
                              loading={isValidating}
                              sx={{ my: 2 }}
                            >
                              {isSubmitting ? "Submitting..." : "Submit"}
                            </LoadingButton>
                            <Btn
                              color="secondary"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                setErrors({});
                                setTouched({}, false);

                                dispatch({ type: "DONE" });
                              }}
                              sx={{ my: 2 }}
                            >
                              {"CANCEL"}
                            </Btn>
                          </>
                        ) : (
                          <>
                            <Btn
                              color="secondary"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch({ type: "EDITABLE" });
                              }}
                              sx={{ my: 2 }}
                            >
                              {"Edit"}
                            </Btn>
                            {state.Attachment && (
                              <Btn
                                color="secondary"
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleAttachment();
                                }}
                                sx={{ my: 2 }}
                              >
                                {"DONE"}
                              </Btn>
                            )}
                          </>
                        )}
                      </Stack>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Row className="show-grid">
                {UID && (
                  <Col xs={24} sm={24} md={24}>
                    <Uploader
                      fileListVisible={false}
                      disabled={!state.Attachment}
                      multiple={false}
                      listType="picture"
                      action="https://backend.dahiyainsurance.com/Upload/AddProfilePic.php"
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      data={{ UID, Type: "Policy" }}
                      onUpload={(File) => {
                        setUploading(true);
                        previewFile(File.blobFile, (value) => {
                          setFileInfo(value);
                        });
                      }}
                      onSuccess={(response, _) => {
                        setUploading(false);
                        toaster.push(
                          <Message type="success">
                            Uploaded successfully
                          </Message>
                        );
                        console.log(response);
                      }}
                      onError={(e) => {
                        console.log(e);
                        setFileInfo(null);
                        setUploading(false);
                        toaster.push(
                          <Message type="error">Upload failed</Message>
                        );
                      }}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                    >
                      <button style={{ width: 300, height: 300 }}>
                        {uploading && <Loader backdrop center />}
                        {fileInfo ? (
                          <img
                            alt=""
                            src={fileInfo}
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <AvatarIcon style={{ fontSize: 80 }} />
                        )}
                      </button>
                    </Uploader>

                    <Uploader
                      disabled={!state.Attachment}
                      removable={!state.Attachment}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                      listType="picture-text"
                      fileList={fileList1}
                      multiple={true}
                      data={{ UID, Type: "Policy" }}
                      onChange={setFileList1}
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      action="https://backend.dahiyainsurance.com/Upload/UploadDocs.php"
                      renderFileInfo={(file, fileElement) => (
                        <span style={{ width: "100%", fontSize: 14 }}>
                          {file.name}
                        </span>
                      )}
                    />
                  </Col>
                )}
              </Row>
              <Row
                className="show-grid"
                style={{ margin: "10px 0px 10px 0px" }}
              >
                <Col xs={24} sm={24} md={24}>
                  {
                    <ul>
                      {docs.map((data) => (
                        <li key={data.UniqueID}>
                          {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                          <a
                            rel="noreferrer"
                            href={`https://backend.dahiyainsurance.com/Upload/assets/${data.Path}`}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                            /* onClick={(e) => getDocs(e, data.UniqueID)} */
                          >
                            {data?.DocType === "pdf" ? (
                              <PictureAsPdf />
                            ) : (
                              <Image />
                            )}{" "}
                            {data.Name}
                          </a>
                          {(permissions.includes(EDIT_POLICY) ||
                            user.Type === "SuperAdmin") && (
                            <a
                              href=""
                              style={{
                                paddingLeft: 5,
                                textDecoration: "none",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveDoc(data.UniqueID);
                              }}
                            >
                              {state.Attachment && (
                                <DeleteForever style={{ fontSize: 15 }} />
                              )}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                </Col>
              </Row>
              {policyServices.length > 0 && (
                <Row
                  className="show-grid"
                  style={{ margin: "10px 0px 10px 0px" }}
                >
                  <Col xs={24} sm={24} md={24}>
                    <h6>Services:</h6>
                    {
                      <ol>
                        {policyServices.map((data) => (
                          <li key={data.UniqueID}>
                            {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                            {(permissions.includes(VIEW_POLICYSERVICE) ||
                              user.Type === "SuperAdmin") && (
                              <Link
                                to={`/Services/ViewPolicyService/${data.UniqueID}`}
                              >
                                {`${data.PolicyNo} - ${data.ServiceWork} - ${data.EnquiryDate}`}
                              </Link>
                            )}
                          </li>
                        ))}
                      </ol>
                    }
                  </Col>
                </Row>
              )}
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24}>
                  <>
                    {index > 0 && (
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#2979ff",
                          margin: 10,
                        }}
                        onClick={goToPrevious}
                        startIcon={<SkipPrevious />}
                      >
                        Previous
                      </Button>
                    )}

                    {index < rowFiltereddata.length - 1 && (
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#2979ff",
                          margin: 10,
                        }}
                        onClick={goToNext}
                        startIcon={<SkipNext />}
                      >
                        Next
                      </Button>
                    )}
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
}
